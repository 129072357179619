import { FlexItem, Stack } from '@ojolabs/layout';
import { Heading6, Typography } from '@houseful/typography';
import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { colors, space } from '@houseful/theme';
import WarningIcon from '@houseful/react-icons/warning';
import WarningCircleIcon from '@houseful/react-icons/warning-circle';
import CheckCircleOutsideIcon from '@houseful/react-icons/check-circle-outside';

export type AlertType = 'success' | 'warning' | 'error' | 'info';

interface IContainer {
  type: AlertType;
}

const Container = styled(Stack)<IContainer>`
  ${variant({
    prop: 'type',
    variants: {
      success: {
        backgroundColor: colors.positiveLight,
      },
      warning: {
        backgroundColor: colors.warningLight,
      },
      error: {
        backgroundColor: colors.negativeLight,
      },
      info: {
        backgroundColor: colors.infoLight,
      },
    },
  })}
`;

const Icon: React.FC<{ type: AlertType }> = ({ type }) => {
  const Component =
    type === 'success'
      ? CheckCircleOutsideIcon
      : type === 'warning'
      ? WarningIcon
      : WarningCircleIcon;

  const fillColor =
    type === 'success'
      ? colors.positiveDark
      : type === 'warning'
      ? colors.yellow500
      : type === 'error'
      ? colors.negativeDark
      : colors.infoDark;

  return <Component fill={fillColor} width="1.5rem" height="1.5rem" />;
};

export interface IAlertBoxBody {
  title?: string;
  children?: React.ReactNode;
}
export const AlertBoxBody: React.FC<IAlertBoxBody> = ({
  title = '',
  children: body,
}) => {
  return (
    <Stack space={space.xs}>
      {!!title && <Heading6>{title}</Heading6>}
      {!!body && <Typography size="sm">{body}</Typography>}
    </Stack>
  );
};

const ALERT_BORDER_RADIUS = '8px';

export interface IAlertBox {
  type?: AlertType;
  title?: string;
  children?: React.ReactNode;
}
export const AlertBox: React.FC<IAlertBox> = ({ type = 'info', children }) => {
  return (
    <Container
      type={type}
      borderRadius={ALERT_BORDER_RADIUS}
      padding={space.lg}
      flexDirection="row"
      space={space.base}
      alignItems="flex-start"
    >
      <FlexItem flexGrow={0} flexShrink={0}>
        <Icon type={type} />
      </FlexItem>
      <FlexItem flexGrow={1}>{children}</FlexItem>
    </Container>
  );
};

AlertBox.displayName = 'AlertBox';

import { Box, Flexbox, HorizontalVerticalCenter, Stack } from '@ojolabs/layout';
import React from 'react';
import { Heading7Kicker, Typography } from '@houseful/typography';
import { colors, space } from '@houseful/theme';
import ChevronRightIcon from '@houseful/react-icons/chevron-right';
import styled from 'styled-components';
import HomeHeartIcon from '@houseful/react-icons/home-heart';

interface NextStepCardProps {
  onClick?: () => void;
}

export const PointerFlexbox = styled(Flexbox)`
  cursor: pointer;
`;

export const NextStepCard: React.FC<NextStepCardProps> = ({
  onClick = () => null,
}) => {
  return (
    <PointerFlexbox
      justifyContent={'space-evenly'}
      flexWrap={'nowrap'}
      alignItems={'center'}
      borderRadius={'0.5rem'}
      borderWidth={1}
      borderColor={colors.lime200}
      backgroundColor={colors.lime100}
      paddingY={space.lg}
      paddingX={space.base}
      onClick={onClick}
      style={{ gap: space.base }}
    >
      <Box width={'2.5rem'} height={'2.5rem'} style={{ flex: '0 0 auto' }}>
        <HorizontalVerticalCenter borderRadius={'100px'} bg={colors.lime200}>
          <HomeHeartIcon
            fill={colors.night}
            style={{ width: '1.5rem', height: '1.5rem' }}
          />
        </HorizontalVerticalCenter>
      </Box>
      <Stack>
        <Heading7Kicker color={colors.lime600} m={space.zero} mb={space.xs}>
          NEXT STEPS:
        </Heading7Kicker>
        <Typography size={'sm'} m={0}>
          Learn more about how Houseful is uniquely positioned to help agents
          win.
        </Typography>
      </Stack>
      <HorizontalVerticalCenter style={{ flex: '0 0 auto' }}>
        <Box width={'1.5rem'} height={'1.5rem'}>
          <ChevronRightIcon fill={colors.night} />
        </Box>
      </HorizontalVerticalCenter>
    </PointerFlexbox>
  );
};

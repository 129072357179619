import React from 'react';
import { HorizontalVerticalCenter, Stack } from '@ojolabs/layout';
import { Heading6, Typography } from '@houseful/typography';
import { colors, space } from '@houseful/theme';
import LightningIcon from '@houseful/react-icons/lightning';
import HandCareIcon from '@houseful/react-icons/hand-care';
import CustomerServiceIcon from '@houseful/react-icons/customer-service';

interface BoxProps {
  title: string;
  body: string;
  // tslint:disable-next-line:no-any
  icon: (props: any) => React.ReactElement;
}

const Box: React.FC<BoxProps> = ({ title, body, icon: Icon }) => {
  return (
    <Stack
      borderStyle="solid"
      borderWidth={space.px}
      borderColor={colors.blue200}
      borderRadius={space.xs}
      padding={space.base}
      space={space.sm}
      flexDirection="row"
    >
      <HorizontalVerticalCenter
        borderRadius={'100px'}
        bg={colors.green100}
        padding={space.xxs}
        width={space.lg}
        height={space.lg}
      >
        <Icon width={space.base} height={space.base} fill={colors.green600} />
      </HorizontalVerticalCenter>
      <Stack space={space.xs}>
        <Heading6>{title}</Heading6>
        <Typography size="sm">{body}</Typography>
      </Stack>
    </Stack>
  );
};

export const RBCMortgageSpecialistIntroSubtitle: React.FC = () => (
  <Stack space={space.xs}>
    <Typography size="sm" color={colors.grey700}>
      Benefits of pairing with an RBC Mortgage Specialist include
    </Typography>
    <Box
      title="Get fast, firm approvals"
      body="Firm approvals within 24 hours from committed, always available, Mortgage Specialists"
      icon={LightningIcon}
    />
    <Box
      title="Get the deal done"
      body="RBC is the one-stop funding source for all of your clients, and can help with non-standard financing needs"
      icon={HandCareIcon}
    />
    <Box
      title="Client first"
      body="Working with clients in their preferred language; financing various property types; and more"
      icon={CustomerServiceIcon}
    />
  </Stack>
);

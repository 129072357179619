import * as React from 'react';
import ArrowLeft from '@houseful/react-icons/arrow-left';
import { SmallProgressBar } from '../GenericComponents/SmallProgressBar';
import { Heading3MobileSerif, Typography } from '@houseful/typography';
import { Box, Center, Flexbox, Stack } from '@ojolabs/layout';
import styled from 'styled-components';
import { Button } from '@houseful/button';
import { defaultTheme } from '../../theme';
import { noop } from '../utils';
import { PropsWithChildren } from 'react';
import { CopyrightDisclaimer } from './CopyrightDisclaimer';
import { space as themeSpace } from '@houseful/theme';
import { LoadingButton } from '@houseful/button/dist/LoadingButton';

export interface CustomHeaderProps {
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement | null;
}
export interface FlowContainerProps extends PropsWithChildren {
  onBack?: () => void;
  flowLength?: number;
  currentIndex?: number;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement | null;
  SubtitleComponent?: React.FC;
  onClickNext: () => void;
  isDisabled: boolean;
  isLoading?: boolean;
  testId: string;
  continueButtonTextOverride?: string | React.ReactElement;
  CustomHeader?: React.FC<CustomHeaderProps>;
  CustomSubHeader?: React.FC;
  CustomFooter?: React.FC;
  space?: string;
  allowSkip?: boolean;
}

const FlowContainerStyled = styled(Stack).attrs({
  flexDirection: 'column',
})`
  max-width: ${defaultTheme.breakpoints[0]}px;
  width: 100%;
  position: fixed;
  padding: ${themeSpace.lg};
  padding-bottom: ${themeSpace.huge};
  top: 0;
  bottom: calc(1rem + env(safe-area-inset-bottom));
  height: 100%;
  overflow: scroll;

  /* hide the scrollbar but still have it usable */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeadingSectionStyled = styled(Stack).attrs({
  flexDirection: 'column',
})`
  margin-top: 0;
`;

const BackArrow = styled(ArrowLeft)`
  cursor: pointer;
  flex-grow: 0;
`;

export const CopyrightDisclaimerContainer = styled(Box)`
  margin-top: ${themeSpace.xl};
`;

const iconContainerStyle = {
  margin: `-${themeSpace.lg} -${themeSpace.lg} ${themeSpace.lg} -${themeSpace.lg}`,
  padding: themeSpace.base,
};

const iconStyle = { height: '1.5em' };

const ContinueButton: React.FC<{
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  continueButtonTextOverride?: string | React.ReactElement;
}> = ({ isDisabled, isLoading, onClick, continueButtonTextOverride }) => {
  return isLoading ? (
    <LoadingButton fluid />
  ) : (
    <Button fluid disabled={isDisabled} onClick={onClick}>
      {continueButtonTextOverride ? continueButtonTextOverride : 'Continue'}
    </Button>
  );
};

const SkipButton: React.FC<{
  isLoading: boolean;
  onClick: () => void;
}> = ({ isLoading, onClick }) => {
  return isLoading ? (
    <LoadingButton fluid housefulType="secondary" />
  ) : (
    <Button fluid housefulType="secondary" onClick={onClick}>
      Skip
    </Button>
  );
};

export const FlowContainer: React.FC<FlowContainerProps> = ({
  children,
  onBack = noop,
  flowLength = 0,
  currentIndex = 0,
  title,
  subtitle,
  SubtitleComponent,
  onClickNext,
  isDisabled = false,
  isLoading = false,
  testId,
  continueButtonTextOverride,
  CustomHeader,
  CustomSubHeader,
  CustomFooter,
  space = themeSpace.lg,
  allowSkip = false,
}) => {
  return (
    <Center centerChildren={true} overflowY={'scroll'} data-testid={testId}>
      <FlowContainerStyled space={space}>
        {CustomHeader ? (
          <CustomHeader title={title} subtitle={subtitle} />
        ) : (
          <>
            <Flexbox
              flexDirection={'row'}
              justifyContent="stretch"
              alignItems="center"
              style={iconContainerStyle}
            >
              <BackArrow
                data-testid="back-arrow"
                style={iconStyle}
                onClick={onBack}
              />
              <Flexbox
                flexGrow={1}
                flexDirection={'row'}
                justifyContent="center"
                alignItems="center"
              >
                <SmallProgressBar
                  max={flowLength}
                  value={currentIndex}
                  label="Progress Bar"
                  id=""
                />
              </Flexbox>
            </Flexbox>
            <HeadingSectionStyled space={space}>
              {CustomSubHeader && <CustomSubHeader />}
              <Heading3MobileSerif>{title}</Heading3MobileSerif>
              {(SubtitleComponent || subtitle) && (
                <Typography size="sm">
                  {SubtitleComponent && <SubtitleComponent />}
                  {subtitle}
                </Typography>
              )}
            </HeadingSectionStyled>
          </>
        )}
        {children}
        <Stack space={themeSpace.lg}>
          <ContinueButton
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={onClickNext}
            continueButtonTextOverride={continueButtonTextOverride}
          />
          {allowSkip && (
            <SkipButton isLoading={isLoading} onClick={onClickNext} />
          )}
        </Stack>
        {CustomFooter && <CustomFooter />}
        <CopyrightDisclaimerContainer>
          <CopyrightDisclaimer />
        </CopyrightDisclaimerContainer>
      </FlowContainerStyled>
    </Center>
  );
};

FlowContainer.displayName = 'FlowContainer';

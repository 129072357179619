import { Typography } from '@houseful/typography';
import React from 'react';
import { Stack } from '@ojolabs/layout';
import { space } from '@houseful/theme';

export const TermsAndConditionsFooter: React.FC = () => (
  <Stack space={space.xs}>
    <Typography data-testid="terms-and-conditions" weight={'medium'} size="sm">
      Terms and Conditions
    </Typography>
    <Typography size="xs">
      By clicking the “Start application” button, you accept the Houseful{' '}
      <a
        href="https://www.houseful.ca/about/terms-of-use/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of use
      </a>
      , including its Privacy section. Message and data rates may apply.
    </Typography>
  </Stack>
);

import * as React from 'react';
import {
  connectSplit,
  selectTreatmentValue,
  initSplitSdk,
  getTreatments,
} from '@splitsoftware/splitio-redux';
import { useAppDispatch } from '../reduxStore';
import { getConfig, SplitTreatments } from '../split';
import { store } from '../reduxStore';
import { MaintenanceScreen } from './MaintenanceScreen';
import Loading from '../GenericComponents/Loading';
import { useSelector } from 'react-redux';
import { selectGetApplicationFieldsStatus } from '../slices/agentApplicationSlice';
import { LOADING, SUCCESS } from '../API/types';
import CanadaFlow from './CanadaFlow';
import { ISplitState } from '@splitsoftware/splitio-redux/types/types';
import { useLocation } from 'react-router-dom';

interface Props {
  splitio: ISplitState;
}

let getApplicationFieldStatusHasLoadedSuccesfully = false;

export const AgentApplication = (props: Props) => {
  const dispatch = useAppDispatch();
  const { splitio } = props;
  const searchParams = new URLSearchParams(useLocation().search);

  React.useEffect(() => {
    dispatch(initSplitSdk({ config: getConfig() }));
    dispatch(
      getTreatments({
        splitNames: Object.values(SplitTreatments),
      })
    );
  }, []);

  const getApplicationFieldStatus = useSelector(
    selectGetApplicationFieldsStatus()
  );

  if (getApplicationFieldStatus === SUCCESS) {
    getApplicationFieldStatusHasLoadedSuccesfully = true;
  }

  //we only want to show loading screen here the very first time we getApplicationFieldStatus - so we flag it after the first time
  const showLoadingScreen =
    getApplicationFieldStatus === LOADING &&
    !getApplicationFieldStatusHasLoadedSuccesfully;

  if (
    searchParams.get('forceState') === 'LOADING' ||
    showLoadingScreen ||
    !splitio.isReady
  ) {
    return <Loading />;
  }

  const treatment = selectTreatmentValue(
    store.getState().splitio,
    SplitTreatments.MAINTENANCE_SCREEN
  );

  if (treatment === 'on' || searchParams.get('forceState') === 'MAINTENANCE') {
    return <MaintenanceScreen />;
  } else {
    return <CanadaFlow />;
  }
};

export default connectSplit()(AgentApplication);

AgentApplication.displayName = 'AgentApplication';

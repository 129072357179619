import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import GitInfo from 'react-git-info/macro';
import { Environment } from '../AgentRecruiting/utils';

const SENTRY_DSN =
  'https://ae617947e64a490e8247c0e0974277ff@o4504769799847936.ingest.sentry.io/4505585745526784';

const sentryIntegrations = [new ExtraErrorData()];

const gitInfo = GitInfo();

export const initializeSentry = (env: Environment) => {
  if (env !== 'local') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: env,
      release: `${gitInfo.branch}-${gitInfo.commit.hash}`,
      integrations: sentryIntegrations,
    });
  }
};

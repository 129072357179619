//this is adapted from agent-tools..
//However we should add the agent-tools one into foundation so we can reuse it

import * as React from 'react';
import styled from 'styled-components';
import { Box } from '@ojolabs/layout';
import * as colors from '@houseful/theme/dist/colors';
import IconHousefulLogomarkOnly from '@houseful/react-icons/houseful-logomark-only';

const StyledCircle = styled.circle`
  & {
    stroke-dasharray: 0, 300;
    stroke-dashoffset: 0;
    animation: dash 1s linear infinite;
    stroke-linecap: round;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 0, 300;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 300, 0;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 0, 300;
      stroke-dashoffset: -300;
    }
  }
`;

const LoadingScreenStyled = styled(Box)`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class Loading extends React.Component {
  static displayName = 'Loading';

  render() {
    return (
      <LoadingScreenStyled>
        <svg
          viewBox={`0 0 100 100`}
          style={{
            position: 'absolute',
            zIndex: 1,
            height: '72px',
            width: '72px',
          }}
        >
          <StyledCircle
            cx={'50'}
            cy={'50'}
            r={'42'}
            fill={'none'}
            stroke={colors.greens.green500}
            strokeWidth={'14'}
          />
        </svg>
        <IconHousefulLogomarkOnly
          style={{
            position: 'absolute',
            zIndex: 0,
            height: '24px',
            width: '24px',
            backgroundColor: colors.primaries.white,
          }}
          fill={colors.primaries.harbour}
        />
      </LoadingScreenStyled>
    );
  }
}

export const SUCCESS = 'SUCCESS';
export const LOADING = 'LOADING';
export const INITIAL = 'INITIAL';
export const ERROR = 'ERROR';

export type RequestStatus =
  | typeof SUCCESS
  | typeof LOADING
  | typeof INITIAL
  | typeof ERROR;

//
export const expectedQueryParams = {
  cellPhone: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  primaryMarket: '',
  selectedZipCodes: '',
  zipcodesHighOpp: '',
  state: '',
  country: '',
  utm_source: '',
  utm_campaign: '',
  utm_id: '',
  utm_medium: '',
};

export type ExpectedQueryParams = {
  [Property in keyof typeof expectedQueryParams]: string;
};

export interface FullRequest {
  data: FullRequestDataFields;
  sessionId: string;
  stepNo: number;
  completed: boolean;
}

export interface ValidPhoneRequest {
  isValidPhone: boolean;
}

export type FullRequestDataFields = StaticDataFields & ApplicationDataFields;

//these are static fields / unused fields that the request object needs (may be used for canadian stuff)
export interface StaticDataFields {
  rbcMember: null | 'Yes' | 'No';
  rbcNumber: null | string;
  consentChecked: boolean;
  website: null;
}

export interface UtmData {
  utmSource?: string | null;
  utmCampaign?: string | null;
  utmTerm?: string | null;
  utmMedium?: string | null;
}

//these are all the fields on the request that come from the query params
export interface QueryParamDataFields extends UtmData {
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  state: string;
  country: Country;
  cities: string[];
  zipCodes: string[];
  highOpportunityMarkets: number | null;
  id: string;
}

//these are all the fields we will fill out via user input in the flow
export interface ApplicationDataFields extends UtmData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
  country: Country;
  cities: string[];
  zipCodes: string[];
  highOpportunityMarkets: number | null;
  referredBy?: Reference | null;
  referenceName?: string | null;
  agentRole?: AgentRole | null;
  experience?: string | null;
  qtyTransactions?: string | null;
  licenseState?: string | null;
  brokerName?: string | null;
  brokerageAddress?: string | null;
  brokerageEmail?: string | null;
  brokeragePhone?: string | null;
  brokerageName?: string | null;
  inTeam?: boolean | null;
  teamMembers?: number | null;
  teamName?: string | null;
  leadReceiverData?: string | null;
  teamMembersData?: string | null;
  hasIsa?: boolean | null;
  isaNumber?: IsaValue | null;
  ojoIsaNumber?: IsaValue | null;
  propertyTypes?: PropertyTypes[] | null;
  language?: AdditionalLanguages[] | null;
  otherLanguages?: string | null;
  otherPropertyTypes?: string | null;
  consentChecked?: boolean;
  workingWithAnRBCMortgageSpecialist?: string | null;
  rbcMortgageSpecialistFullName?: string | null;
  rbcMortgageSpecialistEmail?: string | null;
  rbcMortgageSpecialistPhone?: string | null;
  introduceToAnRBCMortgageSpecialist?: string | null;
}

//these fields are currently duplicated between both QueryParamDataFields and UserInputDataFields.
//we are using this interface to ensure we are handling merging the fields together in the FullRequest.
//eventually, the query params will go away, and this will help with the transition.
export interface SharedDataFields {
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  state: string;
}

const propertyTypes = [
  'COMMERCIAL',
  'LAND',
  'SINGLE_FAMILY_HOMES',
  'MOBILE_MANUFACTURED',
  'CONDOS_TOWNHOMES',
  'OTHER_PROPERTY',
] as const;

export type PropertyTypes = (typeof propertyTypes)[number];

export const additionalLanguages = [
  'SPANISH',
  'CANTONESE',
  'MANDARIN',
  'FRENCH',
];

export type AdditionalLanguages = (typeof additionalLanguages)[number];

// NOTICE: any change on this value could affect the function of the solution
// to add new values please do it at the end
// to remove values check if the order affects to: REFERENCE_OTHER_VALUE
export const reference = [
  'RBC',
  'ANOTHER_AGENT',
  'OTHER_REFERENCE',
  'CONTACTED_BY_OJO',
  'FACEBOOK',
  'LINKEDIN',
  'MY_BROKER',
  'RELOCATION_DIRECTOR',
  'EMAIL_MARKETING',
] as const;

export const REFERENCE_OTHER_VALUE = reference[2];

export type Reference = (typeof reference)[number];

export const country = ['UNITED_STATES', 'CANADA'] as const;

export type Country = (typeof country)[number];

export const agentRole = [
  'AGENT_ON_A_TEAM',
  'INDIVIDUAL_AGENT',
  'ISA_ON_A_TEAM',
  'TEAM_LEAD_AND_RAINMAKER',
] as const;

export type AgentRole = (typeof agentRole)[number];

export const isaValue = [
  'ONE',
  'TWO_THREE',
  'FOUR_FIVE',
  'SIX_TEN',
  'ELEVEN_PLUS',
  'DONT_KNOW',
] as const;

export type IsaValue = (typeof isaValue)[number];

export interface AtlasMunicipality {
  name: string;
  province: string;
  type: string;
  reference: string;
}

import { CustomHeaderProps } from '../Components/FlowContainer';
import {
  FieldsInState,
  InitialStateType,
  InitialStateTypeKeys,
} from '../initialState';
import { isValidSalesforceEmail } from '../utils';
import { ScreenOrder } from './flows';
import React from 'react';
import { ApplicationDataFields } from '../API/types';

export const MULTIPLE_CHOICE = 'multipleChoice';
export const MULTIPLE_CHOICE_RADIO = 'multipleChoiceRadio';
export const TEXT_INPUT = 'textInput';
export const IMAGE = 'image';
export const PROVINCE_DROPDOWN = 'provinceDropdown';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const MULTI_MUNICIPALITY = 'multiMunicipality';
export const NUMBER = 'number';
export const MULTI_LINE = 'multiLine';
export const THANK_YOU_PAGE = 'thankYouPage';
export const READ_ONLY = 'readOnly';
export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';

// For multiple choice
export const MULTIPLE_CHOICE_OTHER_ID = 'Other';

export interface Option {
  buttonText: string;
  buttonValue: string;
}

export type OptionsSelected = string[];

export type OptionsArray = Option[];

export interface Field {
  id: string; //todo TS - I want to make the fields have to be type of field names in initialState, and vice versa.
  title: string;
  type?:
    | typeof PROVINCE_DROPDOWN
    | typeof NUMBER
    | typeof MULTI_LINE
    | typeof EMAIL
    | typeof PHONE
    | typeof MULTI_MUNICIPALITY;
  placeholder?: string;
  required?: boolean;
  maxLength: number;
  validatePhone?: boolean;
  filter?: (value: string) => string;
}

export interface ScreenConfig {
  id: string;
  type: string;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement | null;
  SubtitleComponent?: React.FC;
  isFinalStep?: boolean;
  continueButtonTextOverride?: string | React.ReactElement;
  customHeader?: React.FC<CustomHeaderProps>;
  customSubHeader?: React.FC;
  customFooter?: React.FC;
  queryParamsNotRequired?: boolean;
  shouldBeSkipped?: (state: InitialStateType) => boolean;
  space?: string;
  allowSkip?: boolean;
  resetState?: (state: InitialStateType) => InitialStateType;
  resetApplicationData?: (
    applicationData: ApplicationDataFields
  ) => ApplicationDataFields;
}

export interface MultipleChoiceScreenProps {
  optionsSelected: OptionsSelected;
  multiSelect?: boolean;
  setOptionsSelected: (options: OptionsSelected) => void;
}

export interface TextInputProps {
  fields: FieldsInState;
  setFields: (fields: FieldsInState) => void;
}

export interface MultipleChoiceOption {
  id: string;
  label: string;
  disabled?: boolean;
  onSelectCustom?: (id?: string) => void;
  hideDots?: boolean;
  icon?: React.ReactNode;
}

export interface MultipleChoiceScreenConfig extends ScreenConfig {
  id: InitialStateTypeKeys;
  type: typeof MULTIPLE_CHOICE | typeof MULTIPLE_CHOICE_RADIO;
  options: MultipleChoiceOption[];

  //optional - defaults to false
  multiSelect?: boolean;

  //optional - defaults to vertical
  orientation?: typeof VERTICAL | typeof HORIZONTAL | undefined;

  //OPTIONAL - defaults to (currentIndex: number) => currentIndex + 1
  onNext?: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected,
    entireState?: InitialStateType
  ) => number;

  //OPTIONAL - defaults to disabled until at least one option is selected
  disabled?: (optionsSelected: OptionsSelected) => boolean;

  // [optional] - Declares the ID that would expands the text field on
  // Default: MULTIPLE_CHOICE_OTHER_ID
  otherFreeformCustomId?: string;

  // [optional] - If true, expands a freeform text field following an 'Other' selection
  // Default: true
  expandOtherFreeform?: boolean;
}

export interface TextInputScreenConfig extends ScreenConfig {
  id: InitialStateTypeKeys;
  type: typeof TEXT_INPUT;
  fields: Field[];

  //OPTIONAL - defaults to (currentIndex: number) => currentIndex + 1
  onNext?: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    fields: FieldsInState,
    entireState?: InitialStateType
  ) => number;

  //OPTIONAL - defaults to disabled until all required fields are filled
  disabled?: (fields: FieldsInState) => boolean;
  subSectionTitle?: string | React.ReactElement;
  subSectionBody?: string | React.ReactElement;
}

export const defaultOnNext = (currentIndex: number) => currentIndex + 1;

export const isMissingRequiredTextFields = (
  FieldsInState: FieldsInState,
  fieldsFromConfig: Field[]
) => {
  for (const field of fieldsFromConfig) {
    //disabled if any required fields are not filled
    if (
      field.required !== false &&
      FieldsInState[field.id].value.length === 0
    ) {
      return true;
    }
  }
  return false;
};

export const textFieldsAreValid = (
  FieldsInState: FieldsInState,
  fieldsFromConfig: Field[]
) => {
  for (let index = 0; index < fieldsFromConfig.length; index++) {
    const field = fieldsFromConfig[index];
    //disabled if any required fields are not filled
    if (
      field.required !== false &&
      FieldsInState[field.id].value.length === 0
    ) {
      return true;
    } else if (field.required !== false && field.type === EMAIL) {
      return !isValidSalesforceEmail(FieldsInState[field.id].value);
    }
  }
  return false;
};

export const defaultMultipleChoiceScreenDisabled = (
  optionsSelected: OptionsSelected
) => optionsSelected.length === 0;

export interface ImageScreenConfig extends ScreenConfig {
  type: typeof IMAGE;
  image: string;
  alt?: string;
  onNext?: (currentIndex: number) => number;
}

export interface ThankYouScreenConfig extends ScreenConfig {
  type: typeof THANK_YOU_PAGE;
  learnMoreUrl: string;
}

export interface ReadOnlyScreenConfig extends ScreenConfig {
  type: typeof READ_ONLY;
}

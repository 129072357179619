import { store } from './AgentRecruiting/reduxStore';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AgentApplication from './AgentRecruiting/Components/AgentApplication';
import { MixpanelContextProvider } from './hooks/useMixpanel';
import { EventTrackerProvider } from './hooks/useEventTracker';
import { NavigateWithQueryParams } from './AgentRecruiting/Components/NavigateWithQueryParams';
import { housefulTheme } from '@houseful/theme';
import { ThemeProvider } from 'styled-components';
import '../reset.css';
import '../index.css';

export const ROUTE_NOT_FOUND = '/404';

export interface Props {}

export class App extends React.Component<Props> {
  static displayName = 'App';

  render() {
    return (
      <Provider store={store}>
        <MixpanelContextProvider>
          <EventTrackerProvider>
            <InternalApp />
          </EventTrackerProvider>
        </MixpanelContextProvider>
      </Provider>
    );
  }
}
interface InternalAppProps {}

export const InternalApp: React.FC<InternalAppProps> = () => {
  return (
    <ThemeProvider theme={housefulTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/r/BnvGiiBQ"
            element={<NavigateWithQueryParams to="/ca" />}
          />
          <Route path="/:country/:index" element={<AgentApplication />} />
          <Route path="/:country" element={<AgentApplication />} />
          <Route index element={<AgentApplication />} />
          <Route path="*" element={<AgentApplication />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

InternalApp.displayName = 'InternalApp';

import React from 'react';
import { Stack } from '@ojolabs/layout';
import { Typography } from '@houseful/typography';
import { space } from '@houseful/theme';

export const RBCInfoScreenSubtitle: React.FC = () => (
  <Stack space={space.lg}>
    <Typography size={'sm'}>It pays to team up with RBC.</Typography>

    <Typography size={'sm'}>
      As an eligible professional in our client’s home buying journey, sign up
      for the RBC Referral Awards program and you could receive RBC Rewards
      points on your RBC Rewards credit card for every eligible mortgage
      referral* plus access to the RBC team of mobile mortgage specialists who
      can help you build your business and provide differentiated advice and
      experience.
    </Typography>
  </Stack>
);

import mixpanel from 'mixpanel-browser';
import { PRODUCTION, STAGING, BETA, Environment } from './utils';

export const MIXPANEL_BETA_PROJECT_TOKEN = 'ddc97b9ad79f59d475e2d0f6ec180106';
export const MIXPANEL_PRODUCTION_PROJECT_TOKEN =
  'ba957966f93efeaeba6c07df4433ccdf';

export const mixpanelInit = (env?: Environment) => {
  //uncomment out this "|| !env" if you want mixpanel to fire locally for testing purposes
  if (env === BETA || env === STAGING /*|| !env*/) {
    mixpanel.init(MIXPANEL_BETA_PROJECT_TOKEN, { debug: true });
    return true;
  }
  if (env === PRODUCTION) {
    mixpanel.init(MIXPANEL_PRODUCTION_PROJECT_TOKEN);
    return true;
  }
  return false;
};

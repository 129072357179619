import { Box } from '@ojolabs/layout';
import { Typography } from '@houseful/typography';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEventTracker } from '../../hooks/useEventTracker';
import { ScreenOrder } from '../Config/flows';
import {
  IMAGE,
  MULTIPLE_CHOICE,
  MULTIPLE_CHOICE_RADIO,
  TEXT_INPUT,
  THANK_YOU_PAGE,
} from '../Config/flowTypes';
import { AlertBox, AlertBoxBody } from '../GenericComponents/AlertBox';
import { useAppDispatch } from '../reduxStore';
import {
  selectMatchingStateForConfig,
  setMultipleChoiceAnswer,
  setTextInputAnswer,
} from '../slices/agentApplicationSlice';
import { ThankYouPage } from '../thankyou/ThankYouPage';
import {
  checkIfRequiredFieldsMissing,
  matchingStateHasAtLeastOneInvalidValue,
} from '../utils';
import { FlowContainer } from './FlowContainer';
import { ImageScreen } from './ImageScreen';
import { MultipleChoiceScreen } from './MultipleChoiceScreen';
import { TextInputScreen } from './TextInputScreen';

export const ContentGenerator: React.FC<{
  screens: ScreenOrder;
  pageIndex: number;
  onContinue?: () => void;
  isLoading: boolean;
  isError: boolean;
  testId: string;
}> = ({ screens, pageIndex, onContinue, isLoading, isError, testId }) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tracker = useEventTracker();
  const [continueHasBeenClicked, setContinueHasBeenClicked] =
    React.useState<boolean>(false);
  const pendingStepCompletedRef = React.useRef(null as (() => void) | null);

  // derived
  const keys = Object.keys(screens) as (keyof typeof screens)[];
  const stepName = keys[pageIndex] || keys[0];
  const config = screens[stepName];
  const matchingState = useSelector(selectMatchingStateForConfig(config));
  const isDisabled =
    checkIfRequiredFieldsMissing(config, matchingState) ||
    (continueHasBeenClicked &&
      matchingStateHasAtLeastOneInvalidValue(matchingState));

  const unloadPendingStepCompleted = () => {
    if (pendingStepCompletedRef.current) {
      pendingStepCompletedRef.current();
    }
    pendingStepCompletedRef.current = null;
  };

  const replacePendingStepCompleted = (stepCompletedCb: () => void) => {
    pendingStepCompletedRef.current = stepCompletedCb;
  };

  const onClickNext = async () => {
    !continueHasBeenClicked && setContinueHasBeenClicked(true);

    if (matchingStateHasAtLeastOneInvalidValue(matchingState)) {
      return;
    }

    try {
      onContinue && onContinue();
      unloadPendingStepCompleted();
      setContinueHasBeenClicked(false);
    } catch (e) {
      // TODO: add error handling
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  if (config.type === THANK_YOU_PAGE) {
    return <ThankYouPage config={config} />;
  }

  return (
    <FlowContainer
      title={config.title}
      subtitle={config.subtitle}
      SubtitleComponent={config.SubtitleComponent}
      flowLength={Object.keys(screens).length}
      currentIndex={pageIndex + 1}
      onBack={onBack}
      onClickNext={onClickNext}
      isDisabled={isDisabled}
      isLoading={isLoading}
      //testId is being used to test country redirection
      testId={testId}
      continueButtonTextOverride={config.continueButtonTextOverride}
      CustomHeader={config.customHeader}
      CustomSubHeader={config.customSubHeader}
      CustomFooter={config.customFooter}
      space={config.space}
      allowSkip={config.allowSkip}
    >
      {isError && (
        <Box mb="6">
          <AlertBox type="error">
            <AlertBoxBody title={`An error has occurred`}>
              <Typography as="p" margin="0">
                Go back to try again.
              </Typography>
              {'\n'}Call{' '}
              <Typography as="span" weight="bold">
                (866) 973-1703
              </Typography>{' '}
              if the problem persists.
            </AlertBoxBody>
          </AlertBox>
        </Box>
      )}
      {(config.type === MULTIPLE_CHOICE ||
        config.type === MULTIPLE_CHOICE_RADIO) &&
        matchingState &&
        'selected' in matchingState && (
          <MultipleChoiceScreen
            config={config}
            matchingState={matchingState}
            setSelected={(newSelected: string[]) => {
              dispatch(
                setMultipleChoiceAnswer({
                  configId: config.id,
                  newSelected,
                })
              );
              replacePendingStepCompleted(() =>
                tracker.trackApplicationStepCompleted(
                  stepName,
                  newSelected.toString()
                )
              );
            }}
            onOtherFreeformTextChange={(newFreeformText: string) => {
              dispatch(
                setMultipleChoiceAnswer({
                  configId: config.id,
                  newFreeformText,
                })
              );
              replacePendingStepCompleted(() =>
                tracker.trackApplicationStepCompleted(stepName, 'Other')
              );
            }}
          />
        )}
      {config.type === TEXT_INPUT &&
        matchingState &&
        'fields' in matchingState && (
          <TextInputScreen
            showInlineErrors={continueHasBeenClicked}
            config={config}
            matchingState={matchingState}
            setValueOfField={(
              fieldId: string,
              newValue: string,
              isInvalidInput?: boolean
            ) => {
              dispatch(
                setTextInputAnswer({
                  isInvalidInput,
                  configId: config.id,
                  fieldId,
                  newValue,
                })
              );
              replacePendingStepCompleted(() =>
                tracker.trackApplicationStepCompleted(stepName, newValue)
              );
            }}
          />
        )}
      {config.type === IMAGE && (
        <ImageScreen image={config.image} alt={config.alt || ''} />
      )}
    </FlowContainer>
  );
};

ContentGenerator.displayName = 'Content';

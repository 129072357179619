/* This is copied directly from agent-tools -CN-4045 says to put it into foundation and reference it from there */

import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@houseful/theme';
import { Box } from '@ojolabs/layout';

/**
 * @link https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 */
export const VisuallyHidden = styled(Box)`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

VisuallyHidden.displayName = 'VisuallyHidden';

export interface SmallProgressBarProps {
  max: number;
  value: number;
  label: string;
  id: string;
}

export interface StyledSmallProgressBarProps {
  percentage: number;
}

const WIDTH = '160px';
const HEIGHT = '6px';
const RADIUS = '6px';

export const calcWidth = (max: number, value: number) =>
  Math.round(Math.ceil((value * 100) / max));

export const StyledSmallProgressBar = styled(
  'div'
)<StyledSmallProgressBarProps>`
  background-color: ${colors.blue100};
  width: ${WIDTH};
  height: ${HEIGHT};
  border-radius: ${RADIUS};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: ${colors.green500};
    height: inherit;
    border-radius: inherit;
    transition: width ease 0.4s;
    width: ${({ percentage }) => percentage}%;
  }
`;

export const SmallProgressBar: React.FC<SmallProgressBarProps> = ({
  max,
  value,
  label,
  id,
}) => {
  return (
    <div>
      <VisuallyHidden>
        <label htmlFor={id}>{label}</label>
        <progress max={max} value={value} id={id}>
          {value}%
        </progress>
      </VisuallyHidden>
      <StyledSmallProgressBar percentage={calcWidth(max, value)} />
    </div>
  );
};

SmallProgressBar.displayName = 'SmallProgressBar';

import { getEnv, STAGING, PRODUCTION, BETA } from './utils';
import React from 'react';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { useSelector } from 'react-redux';
import { RootState } from './reduxStore';
import { ISplitState } from '@splitsoftware/splitio-redux/types/types';

export const getConfig = () => {
  const env = getEnv();
  let authorizationKey;
  let features = {};

  switch (env) {
    case STAGING:
      authorizationKey = 'v9u636r6c4mlle22ncs6f9078l0uh7b0beu1';
      break;
    case PRODUCTION:
      authorizationKey = 'afcrd07sol7d7eq75n81ua2rr453s51dl95l';
      break;
    case BETA:
      authorizationKey = 'u4ki3vbuidif3ll3u9bsrb5uko1jk0o09142';
      break;
    default:
      // default to localhost key (off-the-grid mode)
      authorizationKey = 'localhost';
      // local treatments
      features = {
        'agent-recruiting_show_maintenance_screen': 'off',
      };
      break;
  }

  return {
    core: {
      authorizationKey,
      //currently we are doing a static ID since our initial use case is just a feature flag. Further down the line when we do A/B testing, we'll want to initialize this key with the user's sessionId;s
      key: 'STATIC_ID',
    },
    features: features,
  };
};

interface SplitComponentProps {
  splitName: string;
  onElement: React.ReactElement;
  // tslint:disable-next-line:no-any
  key?: any;
  defaultValue?: string | undefined;
  offElement?: React.ReactElement;
  preventFlickering?: boolean;
}

export const SplitComponent: React.FC<SplitComponentProps> = ({
  splitName,
  onElement,
  key,
  defaultValue,
  offElement,
  preventFlickering = true,
}) => {
  const splitio = useSelector<RootState, ISplitState>(state => state.splitio);
  if (preventFlickering && !splitio.isReady) {
    return null;
  }
  return selectTreatmentValue(splitio, splitName, key, defaultValue) === 'on'
    ? onElement
    : offElement ?? null;
};

SplitComponent.displayName = 'SplitComponent';

export enum SplitTreatments {
  MAINTENANCE_SCREEN = 'agent-recruiting_show_maintenance_screen',
}

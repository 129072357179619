import React from 'react';
import { ClusterWithInnerElement, Stack } from '@ojolabs/layout';
import { splitOnIndex } from '../utils';
import { RadioGroup, RadioWithLabel } from '@houseful/radio';

export interface RadioSelectOption {
  id: string;
  label: string;
  disabled?: boolean;
}

export interface RadioSelectProps {
  options: Readonly<Array<RadioSelectOption>>;
  selected?: string;
  onSelect(id: string): void;
  splitOnIndex?: number;
  mode: 'cluster' | 'stack';
}

export const radioButtonsMapper =
  ({ selected }: Pick<RadioSelectProps, 'selected'>) =>
  ({ id, label, disabled }: RadioSelectOption) => {
    const isSelected = selected === id;

    return (
      <RadioWithLabel
        data-test={id}
        key={id}
        checked={isSelected}
        label={label}
        value={id}
        disabled={disabled}
      />
    );
  };

export const RadioSelect: React.FC<RadioSelectProps> = ({
  options,
  selected,
  onSelect,
  splitOnIndex: index = 0,
  mode = 'cluster',
}) => {
  const [first] = splitOnIndex(index, options);

  return (
    <RadioGroup
      value={selected}
      onChange={element => {
        onSelect(element.target.value);
      }}
    >
      {mode === 'cluster' ? (
        <ClusterWithInnerElement className={'radio-cluster'}>
          {first.map(radioButtonsMapper({ selected }))}
        </ClusterWithInnerElement>
      ) : (
        <Stack flexDirection={'column'}>
          {first.map(radioButtonsMapper({ selected }))}
        </Stack>
      )}
    </RadioGroup>
  );
};

RadioSelect.displayName = 'RadioSelect';

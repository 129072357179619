import React, { ChangeEvent, FocusEvent } from 'react';
import {
  NUMBER,
  MULTI_LINE,
  EMAIL,
  PHONE,
  PROVINCE_DROPDOWN,
  MULTI_MUNICIPALITY,
} from '../Config/flowTypes';
import { TextField, MultiLineTextField } from '@houseful/text-field';
import { Typography } from '@houseful/typography';
import { Selector } from '@houseful/selector';
import { TextInputScreenConfig } from '../Config/flowTypes';
import {
  filterStringToOnlyNumbers,
  isValidSalesforceEmail,
  isPhoneNumber,
  provinceDropdownOptions,
  multiSelectToString,
  stringToMultiSelect,
} from '../utils';
import { TextInputState } from '../initialState';
import { Box, Stack } from '@ojolabs/layout';
import { TelephoneInput } from '../GenericComponents/PhoneInput';
import { useAppDispatch } from '../reduxStore';
import { getValidatePhone } from '../API/client';
import MunicipalityMultiSelect from './MunicipalityMultiSelect';
import { space } from '@houseful/theme';

export interface TextInputScreenProps {
  config: TextInputScreenConfig;
  matchingState: TextInputState;
  setValueOfField: (
    fieldTitle: string,
    newValue: string,
    isInvalidInput?: boolean
  ) => void;
  showInlineErrors?: boolean;
}

export interface DropdownOption {
  id: string;
  name: string;
  hidden?: boolean;
}

export const TextInputScreen: React.FC<TextInputScreenProps> = ({
  config,
  matchingState,
  setValueOfField,
  showInlineErrors = false,
}) => {
  const { fields, subSectionBody, subSectionTitle } = config;
  const fieldValues = matchingState.fields;
  const dispatch = useAppDispatch();

  const isPhoneNumberValid = async (phoneNumber: string): Promise<boolean> => {
    const response = await dispatch(getValidatePhone(phoneNumber))
      .unwrap()
      .catch(() => null);
    return response?.isValidPhone ?? true;
  };

  return (
    <Stack
      flexDirection={'column'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      space={space.base}
    >
      {fields.map(
        ({
          title,
          type,
          placeholder,
          required = true,
          id,
          maxLength,
          validatePhone = false,
          filter,
        }) => {
          const isInvalidInput = fieldValues[id].isInvalidInput;
          const state = isInvalidInput && showInlineErrors ? 'error' : 'valid';

          return (
            <Box key={title} data-testid={id} width={1}>
              {type === MULTI_LINE && (
                <MultiLineTextField
                  label={title}
                  required={required}
                  state={state}
                  fluid={true}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    setValueOfField(id, newValue);
                  }}
                />
              )}
              {/* todo: change this to masked input
              https://ojolabs.atlassian.net/browse/CN-4115 */}
              {type === NUMBER && (
                <TextField
                  id={`${id}-text`}
                  label={title}
                  required={required}
                  state={state}
                  fluid={true}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const onlyNumbers = filterStringToOnlyNumbers(
                      e.target.value
                    );
                    setValueOfField(id, onlyNumbers);
                  }}
                />
              )}
              {type === PROVINCE_DROPDOWN && (
                <Selector
                  id={`${id}-select`}
                  label={title}
                  required={required}
                  data-test="province-dropdown"
                  fluid={true}
                  value={fieldValues[id].value}
                  options={provinceDropdownOptions}
                  placeholder="Choose Province"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const rawValue = e.target.value;
                    const newValue =
                      filter === undefined ? rawValue : filter(rawValue);
                    setValueOfField(id, newValue);
                  }}
                />
              )}
              {type === undefined && (
                <TextField
                  id={`${id}-text`}
                  label={title}
                  required={required}
                  state={state}
                  fluid={true}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const rawValue = e.target.value;
                    const newValue =
                      filter === undefined ? rawValue : filter(rawValue);
                    setValueOfField(id, newValue);
                  }}
                />
              )}
              {type === EMAIL && (
                <TextField
                  id={`${id}-text`}
                  label={title}
                  required={required}
                  state={state}
                  errorMessage={'Please enter a valid email address'}
                  fluid={true}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValueOfField(
                      id,
                      e.target.value.toLocaleLowerCase(),
                      !isValidSalesforceEmail(
                        e.target.value.toLocaleLowerCase()
                      )
                    );
                  }}
                />
              )}
              {type === PHONE && (
                <TelephoneInput
                  label={title}
                  required={required}
                  shouldConvertToE164
                  fluid
                  state={state}
                  errorMessage={
                    'Invalid phone number. Please use a mobile phone number.'
                  }
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  onChange={(num: string) => {
                    setValueOfField(
                      id,
                      num,
                      num.length > 0 && !isPhoneNumber(num)
                    );
                  }}
                  onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    if (validatePhone) {
                      isPhoneNumberValid(e.target.value).then(isValid => {
                        if (!isValid) {
                          setValueOfField(id, e.target.value, true);
                        }
                      });
                    }
                  }}
                />
              )}
              {type === MULTI_MUNICIPALITY && (
                <MunicipalityMultiSelect
                  label={title}
                  required={required}
                  fluid={true}
                  selected={stringToMultiSelect(fieldValues[id].value)}
                  onChange={(selected: string[]) => {
                    setValueOfField(
                      id,
                      multiSelectToString(selected),
                      selected.length === 0
                    );
                  }}
                />
              )}
            </Box>
          );
        }
      )}
      {subSectionTitle && (
        <Typography as="p" weight="bold" size="sm">
          {subSectionTitle}
        </Typography>
      )}
      {subSectionBody && (
        <Typography as="p" size="sm">
          {subSectionBody}
        </Typography>
      )}
    </Stack>
  );
};

import React from 'react';
import { Box } from '@ojolabs/layout';
import styled from 'styled-components';
import rbcLogo from '../../../assets/images/rbc-logo.svg';

const ImageStyled = styled.img`
  height: 4.5rem;
  max-width: 100%;
  object-fit: cover;
  object-position: left;
`;

export const RBCScreenSubHeader: React.FC = () => (
  <Box>
    <ImageStyled src={rbcLogo} alt={'rbc Logo'} title={'rbc Logo'} />
  </Box>
);

import React from 'react';
import { Flexbox, Stack } from '@ojolabs/layout';
import { Heading3MobileSerif, Typography } from '@houseful/typography';
import { colors, space } from '@houseful/theme';
import { CustomHeaderProps } from './FlowContainer';
import IconHousefulLogoHorizontal from '@houseful/react-icons/houseful-logo-horizontal';

export const HousefulLogoHeader: React.FC<CustomHeaderProps> = ({
  title,
  subtitle,
}) => (
  <Stack
    flexDirection={'column'}
    justifyContent={'center'}
    space={space.xl}
    mt={space.sm}
  >
    <Flexbox
      flexDirection={'row'}
      justifyContent={'center'}
      data-testid="houseful-logo"
    >
      <IconHousefulLogoHorizontal fill={colors.harbour} height={'1.375rem'} />
    </Flexbox>
    <Stack flexDirection={'column'} justifyContent={'center'} space={space.xs}>
      <Heading3MobileSerif textAlign="center">{title}</Heading3MobileSerif>
      {subtitle && (
        <Typography textAlign="center" size="sm" color={colors.grey600}>
          {subtitle}
        </Typography>
      )}
    </Stack>
  </Stack>
);

import React from 'react';
import { Stack } from '@ojolabs/layout';
import { Typography } from '@houseful/typography';

export const RBCDisclaimerSubtitle: React.FC = () => (
  <Stack>
    <Typography size={'sm'}>
      OJOHome Canada Ltd. (“Houseful”) is a wholly-owned subsidiary of Royal
      Bank of Canada.
      <br />
      <br />
      To be eligible to enroll in the RBC Referral Awards Program (“Program”)
      you must be a Canadian resident and either (i) working as a realtor in
      Canada (ii) an employee of a builder or (iii) an insurance broker,
      independent financial planner/advisor, lawyer or accountant, except in
      Quebec, where you must be a Canadian resident and who is working as a duly
      licensed real estate agent in Quebec in accordance with the provincial
      legislation in place, and who meets all the Program’s eligibility
      requirements, as well as any other Eligible Participant as determined
      solely by Royal Bank from time to time, who has enrolled in the Program
      and meets all the Program’s eligibility requirements in accordance with
      applicable laws and/or the Eligible Participant’s respective industry,
      professional and regulatory requirements and obligations. Eligible
      Participants can elect to enrol as either a “Points Member” or a
      “Non-Points Member” (except in Alberta where Participants may only enrol
      in the Program as Non-Points Members). To enrol as a Points Member, an
      Eligible Participant must either: (i) be a Primary Applicant or a
      Co-Applicant on a Personal Credit Card Account, (ii) be a Primary Account
      Owner or a Joint Account Owner of a Personal Deposit Account, or (iii)
      have a Card on a Business Credit Card Account and have redemption rights
      over the Points in the RBC Rewards Account tied to their Card, as per the
      “Points Ownership” section of the RBC Rewards Terms and Conditions. Other
      conditions apply. Program may be amended at any time or terminated upon
      notice. For complete terms and conditions applicable to the Program,
      including defined terms, refer to the RBC Referral Awards Program Terms
      and Conditions, which are available upon enrolment in the Program.
      Employees of Royal Bank, its subsidiaries or its affiliates are not
      eligible to enrol in the Program. If you are a Points Member, the RBC
      Rewards Terms and Conditions also apply, which include terms and
      conditions relating to points redemption and expiry. The RBC Rewards Terms
      and Conditions can be found at{' '}
      <a href="https://www.rbcrewards.com/#!/terms-and-conditions">
        www.rbcrewards.com/#!/terms-and-conditions
      </a>
    </Typography>
  </Stack>
);

import { canadaScreenOrder } from './Config/flows';
import { TEXT_INPUT } from './Config/flowTypes';

export interface MultipleChoiceState {
  selected: string[];
  // Remains unchanged from INITIAL_STATE if 'Other' is not an option
  otherFreeformText: string;
}

export interface FieldsInState {
  [key: string]: {
    value: string;
    isInvalidInput: boolean;
  };
}

export interface TextInputState {
  //where the key is the title of a field, and the value is the value
  fields: FieldsInState;
}

//some configs in screenOrder are display only and don't need state
export type ScreenOrderWithOnlyStateFields = Omit<
  typeof canadaScreenOrder,
  | 'signingUpAsATeamSplashPage'
  | 'thankYouScreen'
  | 'teamDeadEndPage'
  | 'introductionToAnRBCMortgageSpecialistInfo'
  | 'rbcInfoScreen'
  | 'rbcDisclaimer'
>;

export type InitialStateType = {
  [Property in keyof ScreenOrderWithOnlyStateFields]: ScreenOrderWithOnlyStateFields[Property] extends {
    type: typeof TEXT_INPUT;
  }
    ? TextInputState
    : MultipleChoiceState;
};

export type InitialStateTypeKeys = keyof InitialStateType;

export const initialState: InitialStateType = {
  howDidYouHearAboutOjo: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  //todo - these field names should HAVE to match the field names in screenOrder
  whoReferredYou: {
    fields: {
      referenceName: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  whichOfTheFollowingDescribesYou: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  howManyYearsOfExperienceDoYouHave: {
    fields: {
      textValue: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  howManyTransactionsClosed: {
    fields: {
      qtyTransactions: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  brokerageInformation: {
    fields: {
      brokerageName: {
        value: '',
        isInvalidInput: false,
      },
      brokerageAddress: {
        value: '',
        isInvalidInput: false,
      },
      brokerName: {
        value: '',
        isInvalidInput: false,
      },
      brokerageEmail: {
        value: '',
        isInvalidInput: false,
      },
      brokeragePhone: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  workingWithAnRBCMortgageSpecialist: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  rbcMortgageSpecialistInformation: {
    fields: {
      rbcMortgageSpecialistFullName: {
        value: '',
        isInvalidInput: false,
      },
      rbcMortgageSpecialistEmail: {
        value: '',
        isInvalidInput: false,
      },
      rbcMortgageSpecialistPhone: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  introduceToAnRBCMortgageSpecialist: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  rbcMember: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  rbcNumber: {
    fields: {
      rbcNumber: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  areYouSigningUpAsTeam: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  teamInformation: {
    fields: {
      teamMembers: {
        value: '',
        isInvalidInput: false,
      },
      teamName: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  leadReceiver: {
    fields: {
      leadReceiverData: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  teamMembers: {
    fields: {
      teamMembers: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
  doesYourTeamHaveISA: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  howManyISAsWork: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  howManyISAsReceiveLeads: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  propertyTypes: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  additionalLanguagePreference: {
    selected: [],
    otherFreeformText: '',
  },
  consentPage: {
    selected: [] as string[],
    otherFreeformText: '',
  },
  contactInfo: {
    fields: {
      firstName: {
        value: '',
        isInvalidInput: false,
      },
      lastName: {
        value: '',
        isInvalidInput: false,
      },
      phone: {
        value: '',
        isInvalidInput: false,
      },
      email: {
        value: '',
        isInvalidInput: false,
      },
      province: {
        value: '',
        isInvalidInput: false,
      },
      cities: {
        value: '',
        isInvalidInput: false,
      },
    },
  },
};

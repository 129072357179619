import React from 'react';
import {
  MULTIPLE_CHOICE_RADIO,
  MULTIPLE_CHOICE_OTHER_ID,
  THANK_YOU_PAGE,
  MULTIPLE_CHOICE,
  MultipleChoiceScreenConfig,
  TEXT_INPUT,
  TextInputScreenConfig,
  NUMBER,
  OptionsSelected,
  IMAGE,
  ImageScreenConfig,
  MULTI_LINE,
  EMAIL,
  PHONE,
  HORIZONTAL,
  ThankYouScreenConfig,
  ReadOnlyScreenConfig,
  READ_ONLY,
  PROVINCE_DROPDOWN,
  MULTI_MUNICIPALITY,
  MultipleChoiceOption,
} from './flowTypes';
import { redirectToHousefulTeamPage } from '../API/queryParamsToRequest';
import teamImage from '../../../assets/images/teams-image.png';
import { RBCScreenSubHeader } from '../Components/RBCScreenSubHeader';
import { RBCDisclaimerSubtitle } from '../Components/RBCDisclaimerSubtitle';
import { RBCInfoScreenSubtitle } from '../Components/RBCInfoScreenSubtitle';
import { FieldsInState, initialState, InitialStateType } from '../initialState';
import { TermsAndConditionsFooter } from '../Components/TermsAndConditionsFooter';
import { HousefulLogoHeader } from '../Components/HousefulLogoHeader';
import { space } from '@houseful/theme';
import { RBCMortgageSpecialistIntroSubtitle } from '../Components/RBCMortgageSpecialistIntroSubtitle';
import { RBCReferralAwardsProgramSubtitle } from '../Components/RBCReferralAwardsProgramSubtitle';
import { ApplicationDataFields } from '../API/types';

export const consentPage: MultipleChoiceScreenConfig = {
  id: 'consentPage',
  title: 'Consent to contact',
  subtitle: (
    <>
      Do you consent to receiving marketing and promotional messages by
      telephone, text message and email from Houseful, including information and
      updates about properties of interest and services and features of Houseful
      and our selected partners? You may withdraw consent at any time. Message
      and data rates may apply. Consent is not required to receive real estate
      services.
    </>
  ),
  type: MULTIPLE_CHOICE_RADIO,
  options: [
    { label: 'I consent', id: 'iConsent' },
    { label: 'I do not consent', id: 'iDoNotConsent' },
  ],
  disabled: () => false,
  otherFreeformCustomId: undefined,
};

// Just using this to test that the app is differentiating between US and Canada - this will be removed once we can confirm.
export const howDidYouHearAboutOjo: MultipleChoiceScreenConfig = {
  id: 'howDidYouHearAboutOjo',
  title: <>How did you hear about the Houseful agent network?</>,
  type: MULTIPLE_CHOICE,
  options: [
    {
      label: 'Contacted by Houseful',
      id: 'Contacted by OJO',
    },
    {
      label: 'Invited by Royal Bank of Canada',
      id: 'Invited by Royal Bank of Canada',
    },
    {
      label: 'Referred by another agent',
      id: 'Referred by another agent',
    },
    {
      label: 'My broker',
      id: 'My broker',
    },
    {
      label: 'Facebook',
      id: 'Facebook',
    },
    {
      label: 'Linkedin',
      id: 'Linkedin',
    },
    {
      label: 'Email Marketing',
      id: 'Email Marketing',
    },
    {
      label: 'My Coach',
      id: 'My Coach',
    },
    {
      label: 'Other',
      id: MULTIPLE_CHOICE_OTHER_ID,
    },
  ],
  onNext: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    selected: string[]
  ) => {
    if (
      selected.includes('Referred by another agent') ||
      selected.includes('My broker')
    ) {
      return getIndexOfConfig('whoReferredYou', screenOrder);
    } else if (selected.length > 0) {
      return getIndexOfConfig('whichOfTheFollowingDescribesYou', screenOrder);
    }
    return currentIndex;
  },
};

export const whoReferredYou: TextInputScreenConfig = {
  id: 'whoReferredYou',
  title: 'Who referred you?',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'referenceName',
      title: 'Referring Agent or Broker Name',
      maxLength: 255,
    },
  ],
};

// This is the list of options for 'whichOfTheFollowingDescribesYou'
const whichOfTheFollowingDescribesYouOptions: MultipleChoiceOption[] = [
  { label: 'Individual Agent', id: 'Individual Agent' },
  {
    label: 'Team Lead',
    id: 'Team Lead and Rainmaker',
  },
  {
    label: 'Client Care Manager on a team',
    id: 'ISA on a team',
  },
  {
    label: 'Agent on a team',
    id: 'Agent on a team',
  },
];

const agentTeamOptions = whichOfTheFollowingDescribesYouOptions.slice(2);

const whichOfTheFollowingDescribesYou: MultipleChoiceScreenConfig = {
  id: 'whichOfTheFollowingDescribesYou',
  title: 'Which of the following describes you?',
  type: MULTIPLE_CHOICE,
  options: whichOfTheFollowingDescribesYouOptions,
  onNext: (_currentIndex, screenOrder, optionsSelected) => {
    if (
      0 < optionsSelected.length &&
      agentTeamOptions.find(
        (option: { id: string }) => option.id === optionsSelected[0]
      )
    ) {
      // redirect to agent team page
      return getIndexOfConfig('teamDeadEndPage', screenOrder);
    } else {
      //else, continue flow
      return getIndexOfConfig('howManyYearsOfExperienceDoYouHave', screenOrder);
    }
  },
};

const teamDeadEndPage: ImageScreenConfig = {
  id: 'teamDeadEndPage',
  title: 'Team sign-up',
  subtitle: (
    <>
      You are welcome to sign up as a team for the Houseful agent network should
      you meet our qualifications. We encourage you to be selective when
      choosing members to participate - this program is optimized for top
      producers and those who have experience with online leads. Please also be
      cognizant of team size - we recommend teams of around 5.
    </>
  ),
  type: IMAGE,
  image: teamImage,
  alt: 'Signing up as a Team',
  onNext: currentIndex => {
    redirectToHousefulTeamPage();
    return currentIndex;
  },
  continueButtonTextOverride: 'Learn more about the program',
};

const howManyYearsOfExperienceDoYouHave: TextInputScreenConfig = {
  id: 'howManyYearsOfExperienceDoYouHave',
  title: 'How many years of experience do you have as a full-time agent?',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'textValue',
      title: 'Years of experience',
      placeholder: 'Type in year in numbers',
      type: NUMBER,
      maxLength: 2,
    },
  ],
};

const howManyTransactionsClosed: TextInputScreenConfig = {
  id: 'howManyTransactionsClosed',
  title: 'How many transactions have you closed in the last 12 months?',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'qtyTransactions',
      title: 'Number of transactions',
      placeholder: 'Type in year in numbers',
      type: NUMBER,
      maxLength: 6,
    },
  ],
};

const brokerageInformation: TextInputScreenConfig = {
  id: 'brokerageInformation',
  title: 'Brokerage information',
  subtitle:
    'Please make sure the provided name and email for the managing broker/broker of record is authorized to sign-off on agent referral agreements.',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'brokerageName',
      title: 'Brokerage name',
      maxLength: 75,
    },
    {
      id: 'brokerageAddress',
      title: 'Brokerage address',
      maxLength: 32768,
    },
    {
      id: 'brokerName',
      title: 'Managing broker / Broker of record full name',
      maxLength: 100,
    },
    {
      id: 'brokerageEmail',
      title: 'Managing broker / Broker of record email',
      type: EMAIL,
      maxLength: 75,
    },
    {
      id: 'brokeragePhone',
      title: 'Managing broker phone number',
      type: PHONE,
      maxLength: 15,
    },
  ],
};

const workingWithAnRBCMortgageSpecialist: MultipleChoiceScreenConfig = {
  id: 'workingWithAnRBCMortgageSpecialist',
  orientation: HORIZONTAL,
  customSubHeader: RBCScreenSubHeader,
  title: 'Are you currently working with an RBC Mortgage Specialist?',
  SubtitleComponent: RBCMortgageSpecialistIntroSubtitle,
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  resetState: (state: InitialStateType) => {
    return {
      ...state,
      rbcMortgageSpecialistInformation:
        initialState.rbcMortgageSpecialistInformation,
      introduceToAnRBCMortgageSpecialist:
        initialState.introduceToAnRBCMortgageSpecialist,
    };
  },
  resetApplicationData: (applicationData: ApplicationDataFields) => {
    return {
      ...applicationData,
      rbcMortgageSpecialistFullName: null,
      rbcMortgageSpecialistEmail: null,
      rbcMortgageSpecialistPhone: null,
      introduceToAnRBCMortgageSpecialist: null,
    };
  },
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return getIndexOfConfig('rbcMortgageSpecialistInformation', screenOrder);
    } else {
      return getIndexOfConfig(
        'introduceToAnRBCMortgageSpecialist',
        screenOrder
      );
    }
  },
};

const rbcMortgageSpecialistInformation: TextInputScreenConfig = {
  id: 'rbcMortgageSpecialistInformation',
  title: 'Provide the details of your RBC Mortgage Specialist.',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'rbcMortgageSpecialistFullName',
      title: 'Full name',
      maxLength: 255,
    },
    {
      id: 'rbcMortgageSpecialistEmail',
      title: 'Email',
      type: EMAIL,
      maxLength: 255,
    },
    {
      id: 'rbcMortgageSpecialistPhone',
      title: 'Phone',
      type: PHONE,
      maxLength: 15,
      required: false,
    },
  ],
  onNext: (_currentIndex: number, screenOrder: ScreenOrder) => {
    return getIndexOfConfig('rbcMember', screenOrder);
  },
};

const introduceToAnRBCMortgageSpecialist: MultipleChoiceScreenConfig = {
  id: 'introduceToAnRBCMortgageSpecialist',
  orientation: HORIZONTAL,
  customSubHeader: RBCScreenSubHeader,
  title: 'Would you like to be introduced to an RBC Mortgage Specialist?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return getIndexOfConfig(
        'introductionToAnRBCMortgageSpecialistInfo',
        screenOrder
      );
    } else {
      return getIndexOfConfig('rbcMember', screenOrder);
    }
  },
};

const introductionToAnRBCMortgageSpecialistInfo: ReadOnlyScreenConfig = {
  id: 'introductionToAnRBCMortgageSpecialistInfo',
  customSubHeader: RBCScreenSubHeader,
  title: 'Great! Someone will be in touch in the next few days.',
  type: READ_ONLY,
};

const rbcMember: MultipleChoiceScreenConfig = {
  id: 'rbcMember',
  orientation: HORIZONTAL,
  customSubHeader: RBCScreenSubHeader,
  title: 'Are you a member of the RBC Referral Awards program?',
  SubtitleComponent: RBCReferralAwardsProgramSubtitle,
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return getIndexOfConfig('rbcNumber', screenOrder);
    } else {
      return getIndexOfConfig('rbcInfoScreen', screenOrder);
    }
  },
};

const rbcNumberFilter = (value: string) =>
  value.replace(/[^A-Za-z0-9]/, '').toUpperCase();

const rbcNumber: TextInputScreenConfig = {
  id: 'rbcNumber',
  customSubHeader: RBCScreenSubHeader,
  title: 'Please enter your RBC Referral Awards Participant ID',
  SubtitleComponent: RBCReferralAwardsProgramSubtitle,
  type: TEXT_INPUT,
  fields: [
    {
      id: 'rbcNumber',
      title: 'Referral Awards Participant ID',
      maxLength: 8,
      placeholder: 'ABC12345',
      required: false,
      filter: rbcNumberFilter,
    },
  ],
  allowSkip: true,
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    _fields: FieldsInState
  ) => {
    return getIndexOfConfig('areYouSigningUpAsTeam', screenOrder);
  },
};

//todo - just make read only text screen
const rbcInfoScreen: ReadOnlyScreenConfig = {
  id: 'rbcInfoScreen',
  customSubHeader: RBCScreenSubHeader,
  title: 'RBC Referral Awards Program',
  SubtitleComponent: RBCInfoScreenSubtitle,

  type: READ_ONLY,
};

const rbcDisclaimer: ReadOnlyScreenConfig = {
  id: 'rbcDisclaimer',
  customSubHeader: RBCScreenSubHeader,
  title: 'RBC Disclaimer',
  SubtitleComponent: RBCDisclaimerSubtitle,
  type: READ_ONLY,
};

const areYouSigningUpAsTeam: MultipleChoiceScreenConfig = {
  id: 'areYouSigningUpAsTeam',
  title: 'Are you signing up as a team?',
  subtitle: (
    <>
      You are welcome to sign up as a team for the Houseful agent network should
      you meet our qualifications. We encourage you to be selective when
      choosing members to participate - this program is optimized for top
      producers and those who have experience with online leads. Please also be
      cognizant of team size - we recommend teams of around 5, although there is
      no minimum or maximum number of team members who can participate in the
      program so long as space allows.
    </>
  ),
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  orientation: HORIZONTAL,
  onNext: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return currentIndex + 1;
    } else {
      return getIndexOfConfig('propertyTypes', screenOrder);
    }
  },
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const signingUpAsATeamSplashPage: ImageScreenConfig = {
  id: 'signingUpAsATeamSplashPage',
  title: 'Signing up as a team',
  subtitle:
    'Be selective when choosing your Lead Receiver and participating agents. Your top-performing agents will fare best in this program.',
  type: IMAGE,
  image: teamImage,
  alt: 'Signing up as a Team',
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const teamInformation: TextInputScreenConfig = {
  id: 'teamInformation',
  type: TEXT_INPUT,
  title: 'Team information',
  subtitle: (
    <>
      Please only count team members who will be receiving referrals from the
      Houseful agent network (including yourself).
    </>
  ),
  fields: [
    {
      id: 'teamName',
      title: 'Team name',
      placeholder: 'Type in team name',
      maxLength: 100,
    },
    {
      id: 'teamMembers',
      title: 'How many team members will be participating?',
      placeholder: 'Type in number',
      type: NUMBER,
      maxLength: 6,
    },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const leadReceiver: TextInputScreenConfig = {
  id: 'leadReceiver',
  type: TEXT_INPUT,
  title: 'Lead receiver',
  subtitle:
    'Please list your lead receiver(s) below in the following format: First name, Last name, Preferred name, Mobile phone number, Email',
  fields: [
    {
      //todo - make the id of these fields have to match to an id in the state
      id: 'leadReceiverData',
      title: 'Lead receiver details',
      placeholder: 'Enter lead receiver details',
      type: MULTI_LINE,
      maxLength: 32000,
    },
  ],
  subSectionTitle: 'What is a lead receiver to us?',
  subSectionBody: (
    <>
      The Lead Receiver is an agent, Client Care Manager, or group of agents on
      your team who you will appoint to receive leads from Houseful and who will
      be initially introduced to the customer via a phone call. Be sure to
      choose individuals who are highly responsive and willing to pick up the
      phone quickly and often.
    </>
  ),
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const teamMembers: TextInputScreenConfig = {
  id: 'teamMembers',
  type: TEXT_INPUT,
  title: 'Team members',
  subtitle:
    'Please list out all additional team members in the following format: First Name, Last Name, Preferred Name, Mobile Phone Number, Email',
  fields: [
    {
      id: 'teamMembers',
      title: 'Team member details',
      placeholder: 'Enter team members',
      type: MULTI_LINE,
      required: false,
      maxLength: 32000,
    },
  ],
  subSectionTitle: 'Who are the team members?',
  subSectionBody: (
    <>
      These are agents who will be working with consumers from the Houseful
      agent network but will not be directly receiving the lead offers.
      Remember, it is highly encouraged that you select your top producers for
      this program.
    </>
  ),
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const thankYouScreenCanada: ThankYouScreenConfig = {
  id: THANK_YOU_PAGE,
  type: THANK_YOU_PAGE,
  title: 'Your application is complete',
  learnMoreUrl: 'https://www.houseful.ca/agent-teams/',
};

const doesYourTeamHaveISA: MultipleChoiceScreenConfig = {
  id: 'doesYourTeamHaveISA',
  title:
    'Does your team have an Client Care Manager who receives leads and distributes them?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  onNext: (currentIndex, screenOrder, optionsSelected) => {
    if (optionsSelected.includes('Yes')) {
      return currentIndex + 1;
    } else {
      return getIndexOfConfig('propertyTypes', screenOrder);
    }
  },
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const howManyISAsWork: MultipleChoiceScreenConfig = {
  id: 'howManyISAsWork',
  title: 'How many Client Care Managers work on your team?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: '1', id: '1' },
    { label: '2-3', id: '2-3' },
    { label: '4-5', id: '4-5' },
    { label: '6-10', id: '6-10' },
    { label: '11+', id: '11+' },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const howManyISAsReceiveLeads: MultipleChoiceScreenConfig = {
  id: 'howManyISAsReceiveLeads',
  title: (
    <>
      How many Client Care Managers will receive these Houseful leads on your
      team?
    </>
  ),
  type: MULTIPLE_CHOICE,
  options: [
    { label: '1', id: '1' },
    { label: '2-3', id: '2-3' },
    { label: '4-5', id: '4-5' },
    { label: '6-10', id: '6-10' },
    { label: '11+', id: '11+' },
    { label: "Don't Know", id: "Don't Know" },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const propertyTypes: MultipleChoiceScreenConfig = {
  id: 'propertyTypes',
  title: 'What property types do you service?',
  subtitle: `Choose as many options as you'd like`,
  type: MULTIPLE_CHOICE,
  multiSelect: true,
  options: [
    { label: 'Single Family Homes', id: 'Single Family Homes' },
    { label: 'Condos / Townhomes', id: 'Condos / Townhomes' },
    { label: 'Mobile / Manufactured', id: 'Mobile / Manufactured' },
    { label: 'Land', id: 'Land' },
    { label: 'Commercial', id: 'Commercial' },
    { label: 'Other', id: MULTIPLE_CHOICE_OTHER_ID },
  ],
};

const additionalLanguagePreferenceCanada: MultipleChoiceScreenConfig = {
  id: 'additionalLanguagePreference',
  isFinalStep: true,
  title: 'Do you speak another language?',
  subtitle:
    'Choose another language you are comfortable speaking with clients (optional).',
  type: MULTIPLE_CHOICE,
  multiSelect: true,
  options: [
    { label: 'Spanish', id: 'Spanish' },
    { label: 'Cantonese', id: 'Cantonese' },
    { label: 'Mandarin', id: 'Mandarin' },
    { label: 'French', id: 'French' },
    { label: 'Other', id: MULTIPLE_CHOICE_OTHER_ID },
  ],
  disabled: () => false,
};

const contactInfo: TextInputScreenConfig = {
  id: 'contactInfo',
  title: 'Apply for the Houseful™ agent network',
  subtitle: 'Ready to apply? Start the process below.',
  type: TEXT_INPUT,
  space: space.base,
  customHeader: props => <HousefulLogoHeader {...props} />,
  customFooter: TermsAndConditionsFooter,
  continueButtonTextOverride: 'Start application',
  queryParamsNotRequired: true,
  fields: [
    {
      id: 'firstName',
      title: 'First name',
      placeholder: 'Jan',
      maxLength: 100,
    },
    {
      id: 'lastName',
      title: 'Last name',
      placeholder: 'Appleseed',
      maxLength: 100,
    },
    {
      id: 'phone',
      title: 'Mobile number',
      placeholder: '(555) 123-4567',
      type: PHONE,
      maxLength: 15,
      validatePhone: true,
    },
    {
      id: 'email',
      title: 'Email',
      placeholder: 'jan.appleseed@email.com',
      type: EMAIL,
      maxLength: 75,
    },
    {
      id: 'province',
      title: 'Province',
      type: PROVINCE_DROPDOWN,
      maxLength: 50,
    },
    {
      id: 'cities',
      title: 'Service areas',
      type: MULTI_MUNICIPALITY,
      maxLength: 0, // Not applicable.
    },
  ],
};

const isIndividualAgent = (state: InitialStateType): boolean =>
  state.whichOfTheFollowingDescribesYou.selected[0] === 'Individual Agent';

export const getIndexOfConfig = (
  configId: Screens,
  screenOrder: ScreenOrder
): number => {
  return Object.keys(screenOrder).indexOf(configId);
};

export type ConfigTypes =
  | MultipleChoiceScreenConfig
  | TextInputScreenConfig
  | ImageScreenConfig
  | ThankYouScreenConfig
  | ReadOnlyScreenConfig;

export const canadaScreenOrder: CanadaScreenOrder = {
  contactInfo,
  consentPage,
  howDidYouHearAboutOjo,
  whoReferredYou,
  whichOfTheFollowingDescribesYou,
  teamDeadEndPage,
  howManyYearsOfExperienceDoYouHave,
  howManyTransactionsClosed,
  brokerageInformation,
  workingWithAnRBCMortgageSpecialist,
  rbcMortgageSpecialistInformation,
  introduceToAnRBCMortgageSpecialist,
  introductionToAnRBCMortgageSpecialistInfo,
  rbcMember,
  rbcNumber,
  rbcInfoScreen,
  rbcDisclaimer,
  areYouSigningUpAsTeam,
  signingUpAsATeamSplashPage,
  teamInformation,
  leadReceiver,
  teamMembers,
  doesYourTeamHaveISA,
  howManyISAsWork,
  howManyISAsReceiveLeads,
  propertyTypes,
  additionalLanguagePreference: additionalLanguagePreferenceCanada,
  thankYouScreen: thankYouScreenCanada,
};

export interface CanadaScreenOrder {
  contactInfo: TextInputScreenConfig;
  consentPage: MultipleChoiceScreenConfig;
  howDidYouHearAboutOjo: MultipleChoiceScreenConfig;
  whoReferredYou: TextInputScreenConfig;
  whichOfTheFollowingDescribesYou: MultipleChoiceScreenConfig;
  teamDeadEndPage: ImageScreenConfig;
  howManyYearsOfExperienceDoYouHave: TextInputScreenConfig;
  howManyTransactionsClosed: TextInputScreenConfig;
  workingWithAnRBCMortgageSpecialist: MultipleChoiceScreenConfig;
  rbcMortgageSpecialistInformation: TextInputScreenConfig;
  introduceToAnRBCMortgageSpecialist: MultipleChoiceScreenConfig;
  introductionToAnRBCMortgageSpecialistInfo: ReadOnlyScreenConfig;
  rbcMember: MultipleChoiceScreenConfig;
  rbcNumber: TextInputScreenConfig;
  rbcInfoScreen: ReadOnlyScreenConfig;
  rbcDisclaimer: ReadOnlyScreenConfig;
  brokerageInformation: TextInputScreenConfig;
  areYouSigningUpAsTeam: MultipleChoiceScreenConfig;
  signingUpAsATeamSplashPage: ImageScreenConfig;
  teamInformation: TextInputScreenConfig;
  leadReceiver: TextInputScreenConfig;
  teamMembers: TextInputScreenConfig;
  doesYourTeamHaveISA: MultipleChoiceScreenConfig;
  howManyISAsWork: MultipleChoiceScreenConfig;
  howManyISAsReceiveLeads: MultipleChoiceScreenConfig;
  propertyTypes: MultipleChoiceScreenConfig;
  additionalLanguagePreference: MultipleChoiceScreenConfig;
  thankYouScreen: ThankYouScreenConfig;
}

type Screens = keyof typeof canadaScreenOrder;

export type ScreenOrder = CanadaScreenOrder;

import * as React from 'react';
import { Flexbox } from '@ojolabs/layout';
import { Typography } from '@houseful/typography';
import HousefulLogoHorizontalIcon from '@houseful/react-icons/houseful-logo-horizontal';
import { colors } from '@houseful/theme';

export const MaintenanceScreen: React.FC = () => {
  return (
    <Flexbox
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems="center"
      margin={'54px'}
    >
      <Flexbox flexDirection={'column'} justifyContent={'center'}>
        <HousefulLogoHorizontalIcon height="3rem" fill={colors.harbour} />
        <Typography as="p" color={colors.grey600}>
          Uh oh!
        </Typography>
      </Flexbox>
      <Typography weight="bold" as="h1" size="lg">
        Our application form is currently unavailable.
      </Typography>

      <Typography as="h3" size="md" textAlign={'center'}>
        Please call us and we’ll be happy to assist you with your application.
      </Typography>

      <Typography as="p" size="md" textAlign={'center'}>
        For agents in <b>Canada</b>, call: <br />
        <a href="tel:8669731703">(866) 973-1703</a>
      </Typography>

      <Typography as="p" size="md" textAlign={'center'}>
        We look forward to assisting you with becoming a part of our network.
      </Typography>
    </Flexbox>
  );
};

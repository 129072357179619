import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './frontEnd/app/App';
import reportWebVitals from './frontEnd/reportWebVitals';
import { initializeSentry } from './frontEnd/app/utils.tsx/sentryUtils';
import { getEnv, LOCAL } from './frontEnd/app/AgentRecruiting/utils';

// For for: "IE does not support classList for SVG elements"
// Details: https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
if (!('classList' in SVGElement.prototype)) {
  /* istanbul ignore next */
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: (className: string) => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1;
        },
      };
    },
  });
}

initializeSentry(getEnv() ?? LOCAL);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { MULTIPLE_CHOICE_OTHER_ID } from '../Config/flowTypes';
import { InitialStateType, MultipleChoiceState } from '../initialState';
import { turnEmptyOrUndefinedStringValuesToNull } from '../utils';
import { ApplicationDataFields, FullRequestDataFields } from './types';

/**
 * Helper function to help get the single / multi select value. If the selected option is Other, we depends on otherFreeformText field instead.
 * @param state the object that carries that multiple choice state
 * @param defaultAnswer the answer to default to other is not selected
 * @param customOtherId if other has a different ID than defaulted, provide here
 * @returns the string value for a given multiple choice either single / multi select
 */
// tslint:disable-next-line:no-any
export const getOtherSelectValue = <T extends any>(
  state: MultipleChoiceState,
  defaultAnswer: T,
  customOtherId: string = MULTIPLE_CHOICE_OTHER_ID
) => {
  const otherSelection = state.selected.find(id => id === customOtherId);
  if (!otherSelection) {
    return defaultAnswer;
  }
  return state.otherFreeformText.trim();
};

// todo - right now we convert every single field every time we hit save... Would be better to only convert new fields.
export const convertStateToRequestFields = (
  state: InitialStateType
): ApplicationDataFields => {
  const referredBySelection = state.howDidYouHearAboutOjo.selected[0];
  const isOtherReferredBy = referredBySelection === MULTIPLE_CHOICE_OTHER_ID;

  return turnEmptyOrUndefinedStringValuesToNull({
    firstName: state.contactInfo.fields.firstName.value,
    lastName: state.contactInfo.fields.lastName.value,
    email: state.contactInfo.fields.email.value,
    phone: state.contactInfo.fields.phone.value,
    state: state.contactInfo.fields.province.value,
    cities: state.contactInfo.fields.cities.value.split(';'),
    referredBy: convertValueToSalesforceValue(referredBySelection),
    // On OTHER, we will not see this question
    referenceName: isOtherReferredBy
      ? getOtherSelectValue(state.howDidYouHearAboutOjo, '')
      : state.whoReferredYou.fields.referenceName.value,
    agentRole: convertValueToSalesforceValue(
      state.whichOfTheFollowingDescribesYou.selected[0]
    ),
    experience: state.howManyYearsOfExperienceDoYouHave.fields.textValue.value,
    qtyTransactions:
      state.howManyTransactionsClosed.fields.qtyTransactions.value,
    licenseState: state.contactInfo.fields.province.value,
    brokerName: state.brokerageInformation.fields.brokerName.value,
    brokerageName: state.brokerageInformation.fields.brokerageName.value,
    brokerageAddress: state.brokerageInformation.fields.brokerageAddress.value,
    brokerageEmail: state.brokerageInformation.fields.brokerageEmail.value,
    brokeragePhone:
      state.brokerageInformation.fields.brokeragePhone.value.substring(1),
    workingWithAnRBCMortgageSpecialist:
      state.workingWithAnRBCMortgageSpecialist.selected[0],
    rbcMortgageSpecialistFullName:
      state.rbcMortgageSpecialistInformation.fields
        .rbcMortgageSpecialistFullName.value,
    rbcMortgageSpecialistEmail:
      state.rbcMortgageSpecialistInformation.fields.rbcMortgageSpecialistEmail
        .value,
    rbcMortgageSpecialistPhone:
      state.rbcMortgageSpecialistInformation.fields.rbcMortgageSpecialistPhone.value.substring(
        1
      ), // Without leading plus sign
    introduceToAnRBCMortgageSpecialist:
      state.introduceToAnRBCMortgageSpecialist.selected[0],
    rbcMember: state.rbcMember.selected[0],
    rbcNumber: state.rbcNumber.fields.rbcNumber.value,
    inTeam: convertValueToSalesforceValue(
      state.areYouSigningUpAsTeam.selected[0]
    ),
    teamMembers: parseInt(state.teamInformation.fields.teamMembers.value, 10),
    teamName: state.teamInformation.fields.teamName.value,
    leadReceiverData: state.leadReceiver.fields.leadReceiverData.value,
    teamMembersData: state.teamMembers.fields.teamMembers.value,
    hasIsa: convertValueToSalesforceValue(
      state.doesYourTeamHaveISA.selected[0]
    ),
    isaNumber: convertValueToSalesforceValue(state.howManyISAsWork.selected[0]),
    ojoIsaNumber: convertValueToSalesforceValue(
      state.howManyISAsReceiveLeads.selected[0]
    ),
    // Multi-select with OTHER
    propertyTypes: convertArrayToSalesforceValues(state.propertyTypes.selected),
    otherPropertyTypes: getOtherSelectValue(state.propertyTypes, ''),
    language: convertArrayToSalesforceValues(
      state.additionalLanguagePreference.selected
    ),
    otherLanguages: getOtherSelectValue(state.additionalLanguagePreference, ''),
    consentChecked: convertConsentToSalesforceValue(
      state.consentPage.selected.length > 0
        ? state.consentPage.selected[0]
        : null
    ),
  }) as FullRequestDataFields;
};

const convertArrayToSalesforceValues = (values: string[]) => {
  return values.map(value => convertValueToSalesforceValue(value));
};

const convertConsentToSalesforceValue = (value: string | null) => {
  if (value === 'iConsent') {
    return true;
  }
  return false;
};

const convertValueToSalesforceValue = (value: string | null) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (value === 'Yes' || value === 'No') {
    return convertToBool(value);
  }

  // the vast majority of values can just be computed by uppercasing and replacing spaces with underscores,
  // but we have a dictionary for those that can not
  if (value in userValuesToSalesforceValues) {
    return userValuesToSalesforceValues[
      value as keyof typeof userValuesToSalesforceValues
    ];
  } else {
    return convertToConstString(value);
  }
};

//eg - "Contacted by OJO" to "CONTACTED_BY_OJO"
const convertToConstString = (s: string) => s.toUpperCase().replace(/ /g, '_');

//eg - "Yes" to true / "No" to false
const convertToBool = (s: string) => s.toUpperCase() === 'YES';

const userValuesToSalesforceValues = {
  'Referred by another agent': 'ANOTHER_AGENT',
  'Invited by Royal Bank of Canada': 'RBC',
  '1': 'ONE',
  '2-3': 'TWO_THREE',
  '4-5': 'FOUR_FIVE',
  '6-10': 'SIX_TEN',
  '11+': 'ELEVEN_PLUS',
  "Don't Know": 'DONT_KNOW',
  'Condos / Townhomes': 'CONDOS_TOWNHOMES',
  'Mobile / Manufactured': 'MOBILE_MANUFACTURED',
  Other: 'OTHER',
};

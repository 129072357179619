import { Heading3MobileSerif, Typography } from '@houseful/typography';
import React from 'react';
import { PageContainer } from '../Components/PageContainer';
import { Box, Center, Stack } from '@ojolabs/layout';
import { colors, space } from '@houseful/theme';
import { NextStepCard } from '../Components/NextStepCard';
import HeartHandshakeIcon from '@houseful/react-icons/heart-handshake';
import headerLogoSvg from '../../../assets/images/ribbon-and-confetti.svg';
import { ThankYouScreenConfig } from '../Config/flowTypes';
import { CopyrightDisclaimer } from '../Components/CopyrightDisclaimer';

export interface ThankYouScreenConfigProps {
  config: ThankYouScreenConfig;
}

const HeaderLogo = <img src={headerLogoSvg} alt={'Ribbon and confetti'} />;

export const ThankYouPage: React.FC<ThankYouScreenConfigProps> = ({
  config,
}) => {
  return (
    <PageContainer>
      <Stack space={space.lg}>
        <Center centerChildren={true}>{HeaderLogo}</Center>

        <Stack space={space.xs}>
          <Typography
            textAlign={'center'}
            color={colors.green500}
            size={'xs'}
            weight={'medium'}
          >
            {'THANK YOU!'}
          </Typography>
          <Heading3MobileSerif textAlign={'center'}>
            {'Your application is complete!'}
          </Heading3MobileSerif>
        </Stack>

        <Typography textAlign={'center'}>
          Once we've had chance to fully review your submission and confirm
          availability in your area, someone from our team will be in touch.
          <br />
          <br />
          Acceptance into the Houseful™ agent network is subject to availability
          in one or more of the areas you've indicated you're able to service.
        </Typography>

        <Box>
          <NextStepCard
            onClick={() => {
              window.open(config.learnMoreUrl, '_self');
            }}
          />
        </Box>

        <Typography size={'sm'} color={colors.grey600} textAlign={'center'}>
          You can safely close this page.
          <br />
          Your application has been submitted.
        </Typography>

        <Stack space={space.xs}>
          <Center centerChildren={true}>
            <HeartHandshakeIcon
              fill={colors.green500}
              style={{ width: '1.5rem', height: '1.5rem' }}
            />
          </Center>
          <Typography textAlign={'center'} color={colors.green500} size={'sm'}>
            Have a great rest of your day!
          </Typography>
        </Stack>

        <Center centerChildren={true} mb={space.lg}>
          <CopyrightDisclaimer />
        </Center>
      </Stack>
    </PageContainer>
  );
};

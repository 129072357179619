// Base theme object for styled-component (and styled-system).
// This object can be extended based on
// styled-system specs: https://styled-system.com/theme-specification/
// Worth to mention that this object is also used by
// some OJO frontend libraries like @ojolabs/layout.
import {
  colors,
  shadows,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  fontFamilies,
} from '@houseful/theme';

export const defaultTheme = {
  space,
  colors,
  breakpoints: [480],
  shadows,
  fontSizes,
  lineHeights,
  fontWeights,
  fontFamilies,
  zIndex: {
    overMapIndex: 1000,
    modalIndex: 2000,
  },
};

export default defaultTheme as typeof defaultTheme;

import React, { PropsWithChildren } from 'react';
import { Button, ButtonSize, IButtonProps } from '@houseful/button';
import { Stack, Box, Flexbox } from '@ojolabs/layout';
import styled from 'styled-components';
import IconCheckCircleFilled from '@houseful/react-icons/check-circle-filled';
import { colors, space } from '@houseful/theme';
import { splitOnIndex } from '../utils';
import {
  HORIZONTAL,
  MultipleChoiceOption,
  VERTICAL,
} from '../Config/flowTypes';

export interface SinglePillSelectProps {
  options: Readonly<Array<MultipleChoiceOption>>;
  selected?: string | Array<string>;
  onSelect(id: string): void;
  size?: ButtonSize;
  splitOnIndex?: number;
  orientation?: typeof HORIZONTAL | typeof VERTICAL;
}

type ISinglePillButtonProps = IButtonProps & { selected: boolean };

export const SinglePillButton = styled(Button)<ISinglePillButtonProps>`
  justify-content: left;
  font-weight: 400;
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed !important' : 'pointer'};
  box-shadow: 0 0 0 1px ${colors.blue200};
  padding-top: ${space.base};
  padding-bottom: ${space.base};

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    box-shadow: 0 0 0 2px ${colors.harbour};
    background-color: ${colors.white};
  }

  ${({ selected }) =>
    !!selected &&
    `
      box-shadow: 0 0 0 2px ${colors.harbour};
    `}

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
SinglePillButton.displayName = 'SinglePillButton';

export const HORIZONTAL_STYLE = {
  flexDirection: `row`,
};

export const LabelBox = styled(Box)`
  text-align: left;
`;

export const resolveOrientation = (orientation?: string) =>
  orientation === HORIZONTAL ? HORIZONTAL_STYLE : {};

export const SinglePillSelectContainer: React.FC<
  PropsWithChildren<{ orientation: string }>
> = ({ children, orientation }) => (
  <Stack {...resolveOrientation(orientation)} space={space.sm}>
    {children}
  </Stack>
);

SinglePillSelectContainer.displayName = 'SinglePillSelectContainer';

export const PILLS_CLUSTER_STYLES = {
  pt: 6,
  mt: 6,
};

export const resolvePillsClusterStyles = (index?: number) =>
  index !== 0 ? PILLS_CLUSTER_STYLES : {};

export const SinglePillsCluster: React.FC<
  PropsWithChildren<{ index?: number }>
> = ({ children, index }) => (
  <Box {...resolvePillsClusterStyles(index)}>{children}</Box>
);

SinglePillsCluster.displayName = 'SinglePillsCluster';

export const pillButtonsMapper =
  ({
    selected,
    onSelect,
    size,
  }: Pick<SinglePillSelectProps, 'selected' | 'size' | 'onSelect'>) =>
  ({
    id,
    label,
    disabled,
    onSelectCustom,
    hideDots,
    icon: Icon,
  }: MultipleChoiceOption) => {
    const isSelected =
      (Array.isArray(selected) && selected.includes(id)) || selected === id;

    return (
      <SinglePillButton
        data-test={id}
        disabled={disabled}
        disableAnimations
        fluid
        selected={isSelected}
        key={id}
        onClick={() => {
          onSelectCustom ? onSelectCustom(id) : onSelect(id);
        }}
        housefulType={'secondary'}
        size={size}
      >
        <Flexbox width="100%" justifyContent={'space-between'}>
          {/* @ts-ignore */}
          {!!Icon && <Icon />}
          <LabelBox as="span" ml={Icon ? '2' : undefined}>
            {label}
          </LabelBox>
          {isSelected && <IconCheckCircleFilled fill={colors.harbour} />}
        </Flexbox>
        {onSelectCustom && !hideDots && '…'}
      </SinglePillButton>
    );
  };

export const SinglePillSelect: React.FC<SinglePillSelectProps> = ({
  options,
  selected,
  onSelect,
  orientation,
  size = 'md',
  splitOnIndex: index = 0,
}) => {
  const [first, last] = splitOnIndex(index, options);
  const setOrientation = orientation ?? VERTICAL;
  return (
    <Box>
      <SinglePillSelectContainer orientation={setOrientation}>
        {first.map(pillButtonsMapper({ selected, onSelect, size }))}
      </SinglePillSelectContainer>
      {last.length > 0 && (
        <SinglePillsCluster>
          <SinglePillSelectContainer orientation={setOrientation}>
            {last.map(pillButtonsMapper({ selected, onSelect, size }))}
          </SinglePillSelectContainer>
        </SinglePillsCluster>
      )}
    </Box>
  );
};

SinglePillSelect.displayName = 'SinglePillSelect';

export interface SinglePillOption {
  id: string;
  label: string;
  icon?: React.ReactNode;
}

export const asPillOptions = <T extends string>(
  options: Array<SinglePillOption | T>
): Readonly<Array<MultipleChoiceOption>> =>
  options.map(
    option =>
      ({
        id:
          typeof option === 'string' ? option : (option as SinglePillOption).id,
        label:
          typeof option === 'string'
            ? option
            : (option as SinglePillOption).label,
        icon:
          typeof option === 'string'
            ? undefined
            : (option as SinglePillOption)?.icon,
      } as MultipleChoiceOption)
  );

export const asPillOption = (
  id: string,
  label: string,
  icon?: React.ReactNode
): SinglePillOption => ({
  id,
  label,
  icon,
});

import React, { PropsWithChildren } from 'react';
import { Dict, Mixpanel } from 'mixpanel-browser';
import { omitNullAndUndefined } from '../AgentRecruiting/utils';
import { useMixpanel, titleCaseObject } from './useMixpanel';
import mixpanel from 'mixpanel-browser';

interface ApplicationEventRequired {
  ApplicationEventType: string;
}
const logIfNotTest = (...args: Parameters<typeof console.log>) => {
  if (process.env.NODE_ENV !== 'test') {
    /* tslint:disable no-console */
    console.log(...args);
  }
};
const createEventTracker = (
  client: Mixpanel,
  trackingQueue: (() => void)[],
  options: { dryrun: boolean; addToQueue: boolean }
) => {
  const { dryrun, addToQueue } = options;

  // -- TOP-LEVEL Helpers
  function internalTrack<E extends string, T extends Dict>(
    eventName: E,
    payload: T
  ) {
    const callback = () => {
      if (dryrun) {
        logIfNotTest('ApplicationEvent', payload);
      } else {
        return client.track(eventName, omitNullAndUndefined(payload));
      }
    };

    if (addToQueue) {
      trackingQueue.push(callback);
    } else {
      callback();
    }
  }

  function internalSetAndRegister<T extends Dict>(fields: T) {
    const setAndRegisterCallbacks = (() => {
      if (dryrun) {
        logIfNotTest('Set & Register', fields);
        return [];
      } else {
        return [
          () => mixpanel.people.set(fields),
          () => mixpanel.register(fields),
        ];
      }
    })();

    if (addToQueue) {
      trackingQueue.push(...setAndRegisterCallbacks);
    } else {
      setAndRegisterCallbacks.forEach(fn => fn());
    }
  }

  // Specific: Tracking
  function trackApplicationEvent<T extends ApplicationEventRequired>(
    payload: T
  ) {
    return internalTrack('ApplicationEvent', payload);
  }

  /// -- Specific: Identity
  function persistCustomerProfileId(cpsId: string) {
    return internalSetAndRegister({ CustomerProfileId: cpsId });
  }

  return {
    // Tracking
    trackApplicationViewed(fields: ApplicationStatusInfoFields) {
      const { sessionStatus, ...rest } = fields;
      return trackApplicationEvent({
        ApplicationEventType: 'Application Viewed',
        ApplicationStatus: fields.sessionStatus,
        ...titleCaseObject(rest ?? {}),
      });
    },
    trackApplicationStepViewed(
      stepName: string,
      stepIndex: number,
      previousStepName: string | null,
      previousStepIndex: number | null
    ) {
      return trackApplicationEvent({
        ApplicationEventType: 'Step Viewed',
        StepName: stepName,
        StepIndex: stepIndex,
        PreviousStepName: previousStepName,
        PreviousStepIndex: previousStepIndex,
      });
    },
    trackApplicationStepCompleted(stepName: string, stepChoice: string) {
      return trackApplicationEvent({
        ApplicationEventType: 'Step Completed',
        StepName: stepName,
        StepChoice: stepChoice,
      });
    },
    trackApplicationCompleted(
      cpsId: string | undefined,
      persistToProfile: boolean = true
    ) {
      if (persistToProfile && cpsId) {
        persistCustomerProfileId(cpsId);
      }
      return trackApplicationEvent({
        ApplicationEventType: 'Application Completed',
        ...(cpsId && { CustomerProfileId: cpsId }),
      });
    },
  };
};

const EventTrackerContext = React.createContext(
  createEventTracker(mixpanel, [], { dryrun: true, addToQueue: true })
);
export const EventTrackerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { client, environment, isIdentified } = useMixpanel();
  // We want to wait after identity initialization logic to dump the calls
  const trackingQueueRef = React.useRef([] as (() => void)[]);
  const tracker = createEventTracker(client, trackingQueueRef.current, {
    dryrun: environment === 'local',
    addToQueue: !isIdentified,
  });

  // Effect to unload trackingQueue after an identity has been identified for tracking
  React.useEffect(() => {
    if (isIdentified && trackingQueueRef.current.length > 0) {
      trackingQueueRef.current.forEach(cb => cb());
    }
  }, [isIdentified, trackingQueueRef.current.length]);

  return (
    <EventTrackerContext.Provider value={tracker}>
      {children}
    </EventTrackerContext.Provider>
  );
};

export function useEventTracker() {
  return React.useContext(EventTrackerContext);
}

export type ApplicationStatus = 'Incomplete' | 'Completed' | 'New';

export interface ViewedNewApplicationFields {
  sessionStatus: 'New';
}
export interface ViewedIncompleteApplicationFields {
  sessionStatus: 'Incomplete';
  previousStepIndex: number;
}
export interface ViewedCompletedApplicationFields {
  sessionStatus: 'Completed';
}

export type ApplicationStatusInfoFields =
  | ViewedNewApplicationFields
  | ViewedIncompleteApplicationFields
  | ViewedCompletedApplicationFields;

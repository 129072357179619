import React, { useState } from 'react';
import Select, { MultiValue, PropsValue } from 'react-select';
import debounce from 'lodash/debounce';
import { useAppDispatch } from '../reduxStore';
import { getAtlasMunicipalities } from '../API/client';
import { INITIAL, LOADING, RequestStatus, SUCCESS } from '../API/types';
import {
  FormControl,
  FormItem,
  IFormControlContext,
  InputLabel,
} from '@houseful/form-helpers';
import styled from 'styled-components';
import { colors, space } from '@houseful/theme';

interface Option {
  label: string;
  value: string;
}

const StyledFormItem = styled(FormItem)`
  padding: 0;
`;

const StyledSelect = styled(Select<Option, true>)`
  width: 100%;

  & .select__control {
    width: 100%;
    border-radius: 0.5rem;
    padding: ${space.xs};
    border: 0;
  }

  & .select__indicator {
    padding: 0;
    display: none;
  }

  & .select__indicator-separator {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }

  & .select__value-container {
    margin: 0;
    padding: 0;
  }

  & .select__input-container,
  & .select__placeholder {
    margin: 0;
    padding: 0.3215rem;
  }

  & .select__value-container--has-value .select__input-container {
    padding: 0 0.3215rem;
  }

  &&& .select__control--is-focused {
    box-shadow: none;
  }

  & .select__option--is-focused {
    background-color: ${colors.lime100};
  }
`;

export interface MunicipalityMultiSelectProps extends IFormControlContext {
  label?: string;
  required?: boolean;
  selected: string[];
  onChange: (selected: string[]) => void;
}

const MunicipalityMultiSelect: React.FC<
  MunicipalityMultiSelectProps
> = props => {
  const [selectedOptions, setSelectedOptions] = useState<PropsValue<Option>>(
    props.selected.map(option => ({
      label: option,
      value: option,
    }))
  );
  const [options, setOptions] = useState<Option[]>([]);
  const [loadOptionsStatus, setLoadOptionsStatus] =
    useState<RequestStatus>(INITIAL);
  const dispatch = useAppDispatch();

  const loadOptions = debounce(async (inputValue: string) => {
    if (inputValue.length < 3) {
      setOptions([]);
      setLoadOptionsStatus(INITIAL);
      return;
    }

    setLoadOptionsStatus(LOADING);

    const municipalities = await dispatch(getAtlasMunicipalities(inputValue))
      .unwrap()
      .catch(() => null);

    const newOptions: Option[] =
      municipalities?.map(municipality => ({
        label: `${municipality.name} (${municipality.province})`,
        value: `${municipality.name} (${municipality.province})`,
      })) || [];

    setOptions(newOptions);
    setLoadOptionsStatus(SUCCESS);
  }, 500);

  const handleChange = (selectedOptions: PropsValue<Option>) => {
    setSelectedOptions(selectedOptions);
    const selected = (selectedOptions as MultiValue<Option>).map(
      option => option.value
    );
    props.onChange(selected);
  };

  const handleInputChange = (inputValue: string) => {
    loadOptions(inputValue);
  };

  const noOptionsMessage = (): string => {
    switch (loadOptionsStatus) {
      case INITIAL:
        return 'Type to search';
      case LOADING:
        return 'Loading...';
      default:
        return 'Nothing found';
    }
  };

  const formControlProps = props as IFormControlContext;

  return (
    <FormControl {...formControlProps}>
      {!!props.label && (
        <InputLabel>
          {props.label}
          {props.required && '*'}
        </InputLabel>
      )}
      <StyledFormItem>
        <StyledSelect
          options={options}
          isMulti
          value={selectedOptions}
          onChange={handleChange}
          onInputChange={handleInputChange}
          placeholder="Toronto (Ontario)"
          noOptionsMessage={noOptionsMessage}
          isClearable
          isSearchable
          classNamePrefix={'select'}
        />
      </StyledFormItem>
    </FormControl>
  );
};

MunicipalityMultiSelect.defaultProps = {
  state: 'valid',
};

export default MunicipalityMultiSelect;

import React from 'react';
import { Typography } from '@houseful/typography';

export const RBCReferralAwardsProgramSubtitle: React.FC = () => (
  <Typography size={'sm'}>
    To find your ID number or see more information, please visit{' '}
    <Typography size={'md'} weight={'medium'}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://referralawards.rbc.com/"
      >
        RBC Referral Awards Program
      </a>
    </Typography>
  </Typography>
);

import React, { PropsWithChildren } from 'react';
import mixpanel from 'mixpanel-browser';
import { Environment, getEnv } from '../AgentRecruiting/utils';
import { mixpanelInit } from '../AgentRecruiting/mixpanelClient';
import Loading from '../AgentRecruiting/GenericComponents/Loading';
import { mapKeys, camelCase, replace, startCase } from 'lodash';
import { ApplicationDataFields } from '../AgentRecruiting/API/types';

export interface AgentIdentity {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  zipCodes: string[];
  numOfZipWithHighOpportunity: number | null;
  sessionId: string;
}
const DEFAULT_IDENTITY: AgentIdentity = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  zipCodes: [],
  numOfZipWithHighOpportunity: null,
  sessionId: '',
};
const MixpanelContext = React.createContext({
  client: mixpanel,
  environment: 'local' as Environment,
  isClientInitialized: false,
  isIdentityInitialized: false,
  isIdentified: false,
  initializeIdentityWithParams: (() => {
    return;
  }) as (params: ApplicationDataFields, sessionId: string) => void,
});

const DEFAULT_ENVIRONMENT: Environment = 'local';
export const MixpanelContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const initializedEnvironmentRef = React.useRef(
    getEnv() ?? DEFAULT_ENVIRONMENT
  );
  const [isClientInitialized, setIsClientInitialized] = React.useState(false);
  const [agentIdentity, setAgentIdentity] =
    React.useState<AgentIdentity>(DEFAULT_IDENTITY);
  const [isIdentityInitialized, setIsIdentityIntialized] =
    React.useState(false);
  const [isIdentified, setIsIdentified] = React.useState(false);

  const identityId = makeAgentIdentityId(
    agentIdentity.phoneNumber,
    agentIdentity.email
  );

  React.useEffect(() => {
    mixpanelInit(initializedEnvironmentRef.current);
    setIsClientInitialized(true);
  }, []);

  React.useEffect(() => {
    if (isClientInitialized && isIdentityInitialized) {
      if (initializedEnvironmentRef.current !== 'local') {
        mixpanel.identify(identityId);
        mixpanel.people.set(titleCaseObject(agentIdentity));
        mixpanel.register(titleCaseObject(agentIdentity));
      } else {
        /* tslint:disable no-console */
        if (process.env.NODE_ENV !== 'test') {
          console.log(
            'Identified',
            `Id=${identityId}`,
            titleCaseObject(agentIdentity)
          );
        }
      }
      setIsIdentified(true);
    }
  }, [isClientInitialized, isIdentityInitialized]);

  if (!isClientInitialized) {
    return <Loading />;
  }

  return (
    <MixpanelContext.Provider
      value={{
        client: mixpanel,
        environment: initializedEnvironmentRef.current,
        isClientInitialized,
        isIdentityInitialized,
        isIdentified,
        initializeIdentityWithParams(
          params: ApplicationDataFields,
          sessionId: string
        ) {
          if (!isIdentityInitialized) {
            const identity = {
              firstName: params.firstName,
              lastName: params.lastName,
              email: params.email,
              phoneNumber: params.phone,
              zipCodes: params.zipCodes,
              numOfZipWithHighOpportunity: params.highOpportunityMarkets,
              sessionId,
            };
            setAgentIdentity(identity);
            setIsIdentityIntialized(true);
          }
        },
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

export const useMixpanel = () => {
  return React.useContext(MixpanelContext);
};

export const makeAgentIdentityId = (firstName: string, email: string) =>
  `${firstName}-${email}`;
export const titleCaseObject = (obj: object): object => {
  return mapKeys(obj, (_, key) => replace(startCase(camelCase(key)), / /g, ''));
};
